<div class="d-flex flex-column login-gradient justify-content-center align-items-center" style="min-height: 100vh;">
    <div class="card shadow-sm middle-card" style="min-height: 60vh; width:80%;">
        <div class="row">
            <div class="col-md-7 mt-3">
                <p class=" display-6 mb-3 text-white">Bombay Medical Stores</p>
                <hr style="color: white; width: 15rem;" />
                <p class=" fs-3 text-white" style="letter-spacing: 1.5px; line-height: 1.2;">Welcome back,<br />Login
                    here</p>
                <p class="text-white-50 fw-lighter" style="letter-spacing: 1.5px;">Making deliveries a little more
                    interesting...</p>
            </div>
            <div class="col-md-5">
                <mat-card class=" px-4 pt-4">
                    <form *ngIf="!otpSent" [formGroup]="loginForm"
                        (ngSubmit)="loginWithPassword?LoginWithPassword() :sendOtp() ">
                        <p class="">To receive One Time Password (OTP) for logging in, please enter your mobile number
                            below.</p>

                        <mat-form-field appearance="outline">
                            <mat-label>Phone Number</mat-label>
                            <input matInput formControlName="username">
                            <mat-error>{{ errorMessage(loginForm,'username') }}</mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="loginWithPassword" appearance="outline">
                            <mat-label>Password</mat-label>
                            <input [type]="showPassword ? 'text' : 'password'" matInput formControlName="password">
                            <mat-icon matSuffix (click)="togglePasswordVisibility()">
                                {{showPassword?'visibility':'visibility_off'}}</mat-icon>
                            <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
                                Field is required
                            </mat-error>
                        </mat-form-field>

                        <p *ngIf="!loginWithPassword" align="end"><a *ngIf="!loginWithPassword" align="end"
                                class="link-primary text-decoration-none " type="button"
                                (click)="handleLoginWithPassword(true)">Login with password</a></p>
                        <p *ngIf="loginWithPassword" align="end"><a *ngIf="loginWithPassword" align="end"
                                class="link-primary text-decoration-none " type="button"
                                (click)="handleLoginWithPassword(false)">Login with OTP</a></p>


                        <!-- <p class=" text-end me-2"><a href="#" class=" text-decoration-none text-dark">Forgot
                                Password?</a></p> -->

                        <div class="d-block mt-2 mb-3">
                            <button mat-raised-button [disabled]="loading" class="w-100 login-button" type="submit">
                                {{loginWithPassword ? "Login": "Send OTP"}}</button>
                            <mat-progress-bar *ngIf="loading" mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                        <!-- <p class="text-center mt-3 mb-0">No account? <a routerLink="/register"
                                class="fw-bold text-decoration-none text-dark">Register</a></p> -->

                    </form>

                    <form *ngIf="otpSent" [formGroup]="otpForm" (ngSubmit)="checkOtpAndLogin()">
                        <p class="">To log in, please enter the One Time Password (OTP) sent to your phone and click the
                            login button below.</p>

                        <mat-form-field appearance="fill">
                            <mat-label>Phone Number</mat-label>
                            <input matInput formControlName="username" readonly>
                            <mat-error>{{ errorMessage(loginForm,'username') }}</mat-error>
                            <mat-hint align="end"><a class="link-primary text-decoration-none" type="button"
                                    (click)="handleChangeNumber()">Change
                                    mobile number</a></mat-hint>
                        </mat-form-field>


                        <mat-form-field appearance="outline">
                            <mat-label>Enter OTP</mat-label>
                            <input matInput formControlName="one_time_pass">
                            <mat-error>{{ errorMessage( otpForm, 'one_time_pass') }}</mat-error>
                        </mat-form-field>


                        <div class="d-block mb-3">
                            <button mat-raised-button [disabled]="loading" class="w-100 login-button"
                                type="submit">Login</button>
                            <mat-progress-bar *ngIf="loading" mode="indeterminate">
                            </mat-progress-bar>
                        </div>
                    </form>
                </mat-card>
            </div>
        </div>
    </div>
</div>