import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface TeamList {
  teamName: string,
  shitTiming: string,
  headName: string,
  headEmail: string,
  headNumber: string;
}
const ELEMENT_DATA: TeamList[] = [
  { teamName: "", shitTiming: "", headName: "", headEmail: "", headNumber: "" },
  { teamName: "", shitTiming: "", headName: "", headEmail: "", headNumber: "" },
  { teamName: "", shitTiming: "", headName: "", headEmail: "", headNumber: "" },
];

@Component({
  selector: 'app-manage-team',
  templateUrl: './manage-team.component.html',
  styleUrls: ['./manage-team.component.scss']
})
export class ManageTeamComponent {


  @Input() value: string = '';
  displayedColumns: string[] = ['index', 'teamName', 'shitTiming', 'headName', 'headEmail', 'headNumber'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = this.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
