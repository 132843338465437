<app-template>
    <app-breadcrumb>
        <li class="breadcrumb-item active">Dashboard</li>
    </app-breadcrumb>

    <div class="row mb-3 g-3">
        <div class='col-md-3 '>
            <mat-card>
                <mat-card-content>
                    <mat-card-title class="mb-3">{{EnquiryData.pending}}</mat-card-title>
                    <mat-card-subtitle>Pending Orders</mat-card-subtitle>
                    <mat-card-footer></mat-card-footer>
                </mat-card-content>
            </mat-card>
        </div>
        <div class='col-md-3 '>
            <mat-card>
                <mat-card-content>
                    <mat-card-title class="mb-3">{{EnquiryData.invoice_generated}}</mat-card-title>
                    <mat-card-subtitle>Invoice Generated</mat-card-subtitle>
                    <mat-card-footer></mat-card-footer>
                </mat-card-content>
            </mat-card>
        </div>
        <div class='col-md-3 '>
            <mat-card>
                <mat-card-content>
                    <mat-card-title class="mb-3">{{EnquiryData.completed}}</mat-card-title>
                    <mat-card-subtitle>Completed</mat-card-subtitle>
                    <mat-card-footer></mat-card-footer>
                </mat-card-content>
            </mat-card>
        </div>
        <div class='col-md-3 '>
            <mat-card>
                <mat-card-content>
                    <mat-card-title class="mb-3">{{EnquiryData.thisWeek}}</mat-card-title>
                    <mat-card-subtitle>This Week</mat-card-subtitle>
                    <mat-card-footer></mat-card-footer>
                </mat-card-content>
            </mat-card>
        </div>
    </div>


</app-template>