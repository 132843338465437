export const DASHBOARD = 'BMS - Dashboard';
export const LOGIN = 'BMS - Login';
export const REGISTER = 'BMS - Register';

export const PROFILE = 'BMS - Profile';
export const HELP = 'BMS - Help';

// CRM 
// Enquiry Module
export const ENQUIRIES = 'BMS - Enquiries';
export const ADD_ENQUIRY = 'BMS - Add Enquiry';
export const EDIT_ENQUIRY = 'BMS - Edit Enquiry';
// Contact Module
export const CONTACTS = 'BMS - Contacts';
export const ADD_CONTACTS = 'BMS - Add Contact';
export const EDIT_CONTACTS = 'BMS - Edit Contact';
// Company Module
export const COMPANIES = 'BMS - Companies';
export const ADD_COMPANY = 'BMS - Add Company';
export const EDIT_COMPANY = 'BMS - Edit Company';

// Orders
// Orders List
export const ALL_ORDERS = 'BMS - All Orders';
export const EDIT_ORDER = 'BMS - Edit Order';




