import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface CompanyList {
  name: string,
  type: string,
  phone: string,
  email: string,
  address: string,
  state: string,
  city: string,
  country: string,
  pincode: string;
}
const ELEMENT_DATA: CompanyList[] = [

];

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent {

  @Input() value: string = '';
  displayedColumns: string[] = ['index', 'name', 'type', 'phone', 'email', 'address', 'state', 'city', 'country', 'pincode'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = this.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
