import { Component } from '@angular/core';

@Component({
  selector: 'app-dispatch-queue',
  templateUrl: './dispatch-queue.component.html',
  styleUrls: ['./dispatch-queue.component.scss']
})
export class DispatchQueueComponent {

}
