import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarStateService } from 'src/app/Service/sidebar-state.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  constructor(private router: Router, public sidebarStateService: SidebarStateService) { }

  productDisplayName = sessionStorage.getItem('product_display_name');
  userFullName = sessionStorage.getItem('user_full_name');

  logout() {
    sessionStorage.clear();
    sessionStorage.removeItem('username');
    this.router.navigate(['/login']);
  }

  toggleSidebar() {
    this.sidebarStateService.toggleSidebar();
  }
}
