import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


export interface TeamList {
  name: string,
  status: string,
  assignedCount: string,
}
const ELEMENT_DATA: TeamList[] = [
  { name: "", status: "", assignedCount: "", },
  { name: "", status: "", assignedCount: "", },
  { name: "", status: "", assignedCount: "", },
];


@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent {
  @Input() value: string = '';
  displayedColumns: string[] = ['index', 'name', 'status', 'assignedCount'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = this.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
