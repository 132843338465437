<h2 mat-dialog-title>Add User</h2>
<div mat-dialog-content>
    <form [formGroup]="addUserForm" id="addUserForm" (ngSubmit)="onSubmit()" >
        <div class="row">
            <div>
                <div class="alert alert-primary" role="alert">The <strong>Phone Number</strong> you enter here will
                    automatically become the
                    <strong>Customer's Username</strong> , which
                    will be used for
                    logging in.<br /><strong>An OTP will be sent to the same number for logging in</strong>.
                </div>
            </div>
        </div>

        <div class="row">

            <mat-form-field appearance="outline" class="col">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="fname">
                <mat-error *ngIf="addUserForm.controls['fname'].hasError('required') ">
                    Field is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lname">
                <mat-error *ngIf="addUserForm.controls['lname'].hasError('required') ">
                    Field is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col">
                <mat-label>Date Of Birth</mat-label>
                <input  matInput formControlName="dob" [matDatepicker]="dob_picker" placeholder="MM/DD/YYYY">
                <mat-datepicker-toggle matIconSuffix [for]="dob_picker"></mat-datepicker-toggle>
                <mat-datepicker #dob_picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="row">

            <mat-form-field appearance="outline" class="col">
                <mat-label>Phone No.</mat-label>
                <input  matInput placeholder="8888888888" type="tel" formControlName="phone">
                <mat-error *ngIf="addUserForm.controls['phone'].hasError('required') ">
                    Field is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
            </mat-form-field>

            <mat-form-field appearance="outline" class="col">
                <mat-label>Role</mat-label>
                <mat-select formControlName="role">
                    <mat-option *ngFor="let item of roleList" [value]="item['role_id']">
                        {{item['name']}} </mat-option>
                </mat-select>
                <mat-error *ngIf="addUserForm.controls['role'].hasError('required') ">
                    Field is required
                </mat-error>
            </mat-form-field>

        </div>


        <div class="row mb-3">
            <mat-form-field appearance="outline" class="col">
                <mat-label>Password</mat-label>
                <input matInput formControlName="password">
                <mat-error *ngIf="addUserForm.controls['password'].hasError('required') ">
                    Field is required
                </mat-error>
                <mat-error *ngIf="addUserForm.controls['password'].hasError('minlength')">
                    Password must be minimum 8 characters long
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col">
                <mat-label>Confirm Password</mat-label>
                <input matInput formControlName="confirmPassword">
                <mat-error *ngIf="addUserForm.controls['confirmPassword'].hasError('required') ">
                    Field is required
                </mat-error>
            </mat-form-field>

        </div>
    </form>

</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
    <button mat-button form="addUserForm" type="submit" [disabled]="!addUserForm.valid || loading">Add</button>
</div>