<div class="row mb-3">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-2">
            <ng-content></ng-content>
        </ol>
        <mat-divider></mat-divider>
    </nav>
    <div class="col-12">
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    </div>
</div>