<app-template>
    <app-breadcrumb [isLoading]='loadingState'>
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item">Orders</li>
        <li class="breadcrumb-item"><a routerLink="/orders-list">Orders List</a></li>
        <li class="breadcrumb-item active">Edit Order</li>
    </app-breadcrumb>

    <app-main-heading>Edit Order</app-main-heading>

    <!-- [ name, email, additional_info, address, created_at, delivery_mode, delivery_type_name, order_status] -->

    <form [formGroup]="editOrderForm" (ngSubmit)="onSubmit()">
        <div class="row d-flex">
            <div class="col-auto ms-auto">
                <!-- <button (click)="DeleteEnquiry()" class="btn btn-danger me-3" mat-raised-button>Delete </button> -->
                <button type="submit" mat-raised-button>Update </button>
            </div>
        </div>

        <div class="row gx-3 mt-3">


            <div class="col-md-4">

                <app-section-heading>Order Details</app-section-heading>
                <hr />
                <div class="row gx-2">
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Order Id</mat-label>
                        <input readonly matInput formControlName="id">
                    </mat-form-field>



                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Invoice No.</mat-label>
                        <input matInput formControlName="invoice_no">
                    </mat-form-field>


                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Ordered At</mat-label>
                        <input matInput readonly type="ordered_at" formControlName="ordered_at">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Order Status</mat-label>
                        <mat-select formControlName="order_status_id">
                            <mat-option *ngFor="let item of orderStatusList" [value]="item['order_status_id']">
                                {{item['order_status']}} </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editOrderForm.controls['order_status_id'].hasError('required') ">
                            Field is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-12">
                        <mat-label>Information For Chemist</mat-label>
                        <textarea matInput readonly formControlName="additional_info"></textarea>
                    </mat-form-field>

                    <mat-radio-group class="col-md-12 mb-3" formControlName="delivery_mode_id">
                        <label class="col-4">Delivery Mode</label>

                        <mat-radio-button class="col-4" *ngFor="let item of deliveryModeList"
                            [value]="item['delivery_mode_id']">
                            {{item['delivery_mode']}}
                        </mat-radio-button>
                    </mat-radio-group>


                    <mat-radio-group class="col-md-12 mb-3" formControlName="delivery_type_id">
                        <label class="col-4">Delivery Type</label>

                        <mat-radio-button class="col-4" *ngFor="let item of deliveryTypeList"
                            [value]="item['delivery_type_id']">
                            {{item['delivery_type_name']}}
                        </mat-radio-button>
                    </mat-radio-group>

                </div>
                <hr />
                <app-section-heading>Prescription Attachment(s)</app-section-heading>
                <div class="row gx-2">
                    <div style="cursor:pointer;" (click)="open(i,1)"
                        *ngFor="let attachment of prescriptionAttachmentList; let i = index;"
                        class="col-3 mx-2 border d-flex justify-content-center align-items-center mat-elevation-z1">
                        <div class="vstack gap-1">
                            <img class="img-fluid" src='{{storageUrl}}/{{attachment}}'>
                            <span class="small">
                                ID: {{editOrderForm.controls['id'].value}}.{{i+1}} </span>
                            <span class=" small">
                                {{editOrderForm.controls['ordered_at'].value | date :'MMM d, y, h:mm a' }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">

                <app-section-heading>Order Handling</app-section-heading>
                <hr />
                <div class="row gx-2 mb-5">
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Est. Delivery Date</mat-label>
                        <input matInput formControlName="esti_delivery_date"
                            [matDatepicker]="estimateDeliveryDatePicker" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle matIconSuffix [for]="estimateDeliveryDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #estimateDeliveryDatePicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Actual Delivery Date</mat-label>
                        <input matInput formControlName="actual_delivery_date"
                            [matDatepicker]="actualDeliveryDatePicker" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle matIconSuffix [for]="actualDeliveryDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #actualDeliveryDatePicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Est. Delivery Slot</mat-label>
                        <mat-select formControlName="esti_delivery_slot">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let item of estimatedDeliverySlotList"
                                [value]="item['delivery_slot_id']">
                                {{item['slot_name']}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="editOrderForm.controls['esti_delivery_slot'].hasError('required') ">
                            Field is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Assigned To</mat-label>
                        <mat-select formControlName="assigned_to">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let item of assignedToList" [value]="item['id']">
                                {{item['first_name']}} {{item['last_name']}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="editOrderForm.controls['assigned_to'].hasError('required') ">
                            Field is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Dispatcher Name</mat-label>
                        <mat-select formControlName="dispatcher"
                            [disabled]="editOrderForm.controls['delivery_mode_id'].value === 1 || editOrderForm.controls['order_status_id'].value! < 5">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let item of dispatcherList" [value]="item['id']">
                                {{item['first_name']}} {{item['last_name']}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="editOrderForm.controls['dispatcher'].hasError('required') ">
                            Field is required
                        </mat-error>
                        <mat-hint *ngIf="editOrderForm.controls['delivery_mode_id'].value === 1">Field not required for
                            pickup orders
                        </mat-hint>
                        <mat-hint
                            *ngIf="editOrderForm.controls['delivery_mode_id'].value != 1 && editOrderForm.controls['order_status_id'].value! < 5 ">Invoice
                            must be generated first
                        </mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Delivery Partner Name</mat-label>
                        <mat-select formControlName="delivery_partner"
                            [disabled]="editOrderForm.controls['delivery_mode_id'].value === 1 || editOrderForm.controls['order_status_id'].value! < 5">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let item of deliveryPartnerList" [value]="item['id']">
                                {{item['first_name']}} {{item['last_name']}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="editOrderForm.controls['delivery_partner'].hasError('required') ">
                            Field is required
                        </mat-error>
                        <mat-hint *ngIf="editOrderForm.controls['delivery_mode_id'].value === 1">Field not required for
                            pickup orders
                        </mat-hint>
                        <mat-hint
                            *ngIf="editOrderForm.controls['delivery_mode_id'].value != 1 && editOrderForm.controls['order_status_id'].value! < 5 ">Invoice
                            must be generated first
                        </mat-hint>
                    </mat-form-field>
                </div>

                <span class="file-label">Attach Invoice Document</span>
                <div class="row gx-2 d-flex justify-content-between align-items-center my-2">
                    <input #fileInput class="col-auto" type="file" accept="jpeg, png, jpg"
                        (change)="handleFileUpload($event)" multiple>
                    <mat-hint class="small ms-2 mb-3">Accepted File Types: JPEG, PNG, JPG</mat-hint>
                    <button [disabled]="(selectedFile === undefined || selectedFile.length === 0)? true: false"
                        type="button" class="col-auto" (click)="uploadFile($event)" color="primary"
                        mat-raised-button>Upload</button>
                </div>
                <hr />
                <!-- <app-section-heading>Invoice Attachment(s)</app-section-heading> -->
                <div class="row g-2">
                    <div style="cursor:pointer;" (click)="open(attachment.key,0)"
                        *ngFor="let attachment of invoiceAttachmentList  | keyvalue"
                        class="col-2 mx-2 border d-flex justify-content-center align-items-center mat-elevation-z1 position-relative">
                        <mat-icon (click)="deleteInvoiceAttachment($event,attachment.key)"
                            class="position-absolute delete-icon" style="top: 0; right:0;">delete</mat-icon>
                        <img class="img-fluid" src='{{storageUrl}}/{{attachment.value}}'>
                    </div>
                </div>

            </div>

            <div class="col-md-4">

                <app-section-heading>Customer Details</app-section-heading>
                <hr />
                <div class="row gx-2">
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Name</mat-label>
                        <input matInput readonly formControlName="name">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Email Address</mat-label>
                        <input matInput readonly formControlName="email">
                    </mat-form-field>

                    <!-- <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Phone Number</mat-label>
                        <input matInput type="phone">
                    </mat-form-field> -->


                    <mat-form-field appearance="outline" class="col-md-12">
                        <mat-label>Delivery Address</mat-label>
                        <textarea matInput readonly type="address" formControlName="address"></textarea>
                    </mat-form-field>

                    <div class="d-flex justify-content-between align-items-baseline">
                        <app-section-heading>Customer Chat</app-section-heading>
                        <button mat-raised-button color="warn" (click)="fetchCustomerChats()">Refresh
                            Chat</button>
                    </div>

                    <div class=" overflow-auto border shadow-sm" style="height: 50vh;" #scrollMe
                        [scrollTop]="scrollMe.scrollHeight">
                        <mat-card class="mb-3 p-0 w-75 "
                            [ngClass]="(item.created_by === item.customer_id)? 'me-auto':'ms-auto'"
                            *ngFor="let item of MessageList; "
                            [style.backgroundColor]="item.created_by === 98 ? '#90EE90' : null">
                            <mat-card-content>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="vstack gap-1">
                                        <span class="">
                                            {{item.Created_by_String}} ,
                                            {{(item.created_by === item.customer_id)? "Customer":"Internal"}} </span>
                                        <span>
                                            {{item.created_at | date :'MMM d, y, h:mm a' }}</span>
                                    </div>


                                    <button mat-icon-button (click)="deleteMessage(item.message_id)">
                                        <mat-icon>delete</mat-icon></button>
                                </div>
                                <mat-divider class="mb-3"></mat-divider>
                                <p> {{item.message}}</p>
                                <!-- <mat-card-footer></mat-card-footer> -->
                            </mat-card-content>
                        </mat-card>
                    </div>


                    <hr />

                    <form [formGroup]="addMessageForm" id="addMessageForm" (ngSubmit)="AddLog()"
                        class="d-flex align-items-baseline">
                        <mat-form-field appearance="outline" class=" flex-grow-1">
                            <mat-label>Add Message</mat-label>
                            <textarea matInput formControlName="message"></textarea>
                        </mat-form-field>

                        <button mat-raised-button type="submit" color="primary"
                            [disabled]="addMessageForm.controls['message'].value === ''"
                            class="d-block ms-3">Send</button>
                    </form>


                </div>
            </div>
        </div>

    </form>
</app-template>