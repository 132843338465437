import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/Common/customValidators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
  }
  enterUsernameDiv: boolean = true;
  enterOtpDiv: boolean = false;

  baseUrl = environment.baseUrl;

  usernameForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
  });

  usernameSubmit() {
    if (this.usernameForm.valid) {
      let obj = this.usernameForm.value;

      this.http.post('https://portalwiz.net/php/account/forgot_password.php', obj)
        .subscribe((res: any) => {
          if (res.success) {
            this.snackBar.open('OTP sent successfully');
            this.enterUsernameDiv = false;
            this.enterOtpDiv = true;
          } else {
            console.log(res);
            this.snackBar.open("An error occurred, please try again");
          }
        });

    }
  }

  ResendOTP() {
    let obj = this.usernameForm.value;

    this.http.post('https://portalwiz.net/php/account/forgot_password.php', obj)
      .subscribe((res: any) => {
        if (res.success) {
          console.log(res);
          this.snackBar.open('OTP sent successfully');
        } else {
          console.log(res);
          this.snackBar.open("An error occurred, please try again");
        }
      });
  }

  otpForm = new FormGroup({
    otp: new FormControl('', [Validators.required]),
    new_password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    confirmPassword: new FormControl('', [Validators.required]),
  }, {
    validators: [CustomValidators.MatchValidator('new_password', 'confirmPassword')],
  });

  passwordMatchError() {
    this.otpForm;
    return this.otpForm.hasError('mismatch') && this.otpForm.controls.confirmPassword.touched;
  }

  otpSubmit() {
    if (this.otpForm.valid) {
      let obj = this.otpForm.value;
      console.log(obj);
      this.http.post('https://portalwiz.net/php/account/check_otp.php', obj)
        .subscribe((res: any) => {
          if (res.success) {
            this.snackBar.open('Password updated successfully');
            this.router.navigate(['/login']);
          } else {
            console.log(res);
            this.snackBar.open("OTP was incorrect, please try again");
          }
        });
    } else {
      if (this.otpForm.controls.confirmPassword.value !== "" && this.otpForm.hasError('mismatch'))
        this.snackBar.open('Password didn\'t match');
    }
  }


}
