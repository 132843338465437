<app-template>
    <app-breadcrumb>
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item">CRM</li>
        <li class="breadcrumb-item active">Companies</li>
    </app-breadcrumb>

    <app-main-heading>All Companies</app-main-heading>

    <div class="row d-flex">
        <div class="col-5 me-auto">
            <mat-form-field class="me-3 w-100">
                <mat-label>Search</mat-label>
                <input matInput type="text" [(ngModel)]="value" (keyup)="applyFilter($event)">
                <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="col-auto">
            <!-- <button mat-raised-button>Add</button> -->
            <button disabled routerLink="/add-company" mat-raised-button>Add</button>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z2 w-100">
                <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef> No. </th>
                    <td mat-cell *matCellDef="let element, let i = index"> {{i+1}} </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Company Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Company Type </th>
                    <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> Phone </th>
                    <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                </ng-container>

                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef> Address </th>
                    <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                </ng-container>

                <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef> City </th>
                    <td mat-cell *matCellDef="let element"> {{element.city}} </td>
                </ng-container>

                <ng-container matColumnDef="pincode">
                    <th mat-header-cell *matHeaderCellDef> Pincode </th>
                    <td mat-cell *matCellDef="let element"> {{element.pincode}} </td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef> State </th>
                    <td mat-cell *matCellDef="let element"> {{element.state}} </td>
                </ng-container>

                <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef> Country </th>
                    <td mat-cell *matCellDef="let element"> {{element.country}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="15">No records to display.</td>
                </tr>

            </table>
            <mat-paginator class="mat-elevation-z2" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
                aria-label="Select no. of rows per page">
            </mat-paginator>
        </div>
    </div>
</app-template>