import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarStateService {
  private sidebarState: boolean = false;
  constructor() { }

  toggleSidebar() {
    this.sidebarState = !this.sidebarState;
  }

  getSidebarState() {
    return this.sidebarState;
  }
}
