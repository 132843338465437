import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface PaymentList {
  paymentDate: string,
  transactionRef: string,
  paymentMode: string,
  contactFname: string,
  contactLname: string,
  attachments: string,
  notes: string,
}

const ELEMENT_DATA: PaymentList[] = [
  { paymentDate: "", transactionRef: "", paymentMode: "", contactFname: "", contactLname: "", attachments: "", notes: "", },
  { paymentDate: "", transactionRef: "", paymentMode: "", contactFname: "", contactLname: "", attachments: "", notes: "", },
  { paymentDate: "", transactionRef: "", paymentMode: "", contactFname: "", contactLname: "", attachments: "", notes: "", }
];

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {
  @Input() value: string = '';
  displayedColumns: string[] = ['index', 'paymentDate', 'transactionRef', 'paymentMode', 'contactFname', 'contactLname', 'attachments', 'notes',];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = this.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
