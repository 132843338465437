import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { newOrderSound } from 'src/app/Common/playNotification';
import { ConfirmDialogComponent } from 'src/app/Dialogs/Common/confirm-dialog/confirm-dialog.component';
import { DialogService } from 'src/app/Service/dialog.service';
import { environment } from 'src/environments/environment';


export interface OrderData {
  id: string,
  user_id: number,
  delivery_type_id: number,
  additional_info: string,
  address: string,
  delivery_mode_id: number,
  order_status_id: number,
  ordered_at: string,
  assigned_name: string,
  dispatcher_name: string,
  delivery_partner_name: string,
  esti_delivery_date: string,
  esti_delivery_slot: string,
  actual_delivery_date: string,
  created_by: string,
  created_at: string,
  updated_at: string,
  delivery_mode: string,
  delivery_type_name: string,
  first_name: string,
  last_name: string,
  order_status: string;
}

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent {
  baseUrl = environment.baseUrl;
  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  // This id is used to clear the time interval
  timeoutId: null | ReturnType<typeof setTimeout> = null;

  latestOrderId: number = 0;

  ngOnInit(): void {
    this.FetchOrders();

    // Below code will check new Orders after every 30 seconds
    this.timeoutId = setInterval(() => {
      this.FetchOrders();
    }, 30000);
  }

  // Here we are clearing the setInterval by using 'clearInterval' Method 
  ngOnDestroy() {
    if (this.timeoutId) {
      clearInterval(this.timeoutId);
    }
  }

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  OrderList: OrderData[] = [];
  tempFilterList: OrderData[] = [];
  loadingState: boolean = true;

  // This variable will be used, to enable disable the main Delete button (In table header)
  deleteButtonDisabled: boolean = true;

  @Input() value: string = '';
  deliveryTypeFilter = new FormControl(0);
  orderStatusFilter = new FormControl(1);


  displayedColumns: string[] = ['ordered_at', 'order_status', 'first_name', 'delivery_mode', 'delivery_type_name', 'assigned_name', 'dispatcher_name', 'delivery_partner_name', 'actual_delivery_date', 'actions'];
  dataSource = new MatTableDataSource(this.OrderList);
  selection = new SelectionModel<Object>(true, []);


  async FetchOrders(): Promise<void> {
    this.loadingState = true;
    this.http.get(`${this.baseUrl}/fetch_orders`)
      .subscribe({
        next: (data: any) => {
          console.log(data);

          this.OrderList = data;
          this.dataSource = new MatTableDataSource(this.OrderList);

          // For first run we need to set the latestOrderId with max Id number
          if (this.latestOrderId === 0) {
            // setting the latest Order ID, which will be used for checking later new orders
            this.latestOrderId = Math.max(...data.map((order: any) => Number(order.id)));
          }

          // Checking for new Orders & making Notification Sound
          if (this.latestOrderId !== 0) {
            // Find the maximum timestamp or unique identifier in the new orders
            const newLatestOrderId = Math.max(...data.map((order: any) => Number(order.id)));

            // Check if the latest order is newer than the previous latest order
            if (newLatestOrderId > this.latestOrderId) {
              // New order found, update the latest order variable
              this.latestOrderId = newLatestOrderId;
              newOrderSound(); //Playing the notification
            }
          }

        },
        error: (err: any) => {
          console.log(err);
          this.OrderList = [];
          this.dataSource = new MatTableDataSource(this.OrderList);
          this.loadingState = false;
          this.snackBar.open('Error while fetching enquiries, please try later');
        }, complete: () => {
          this.applyRadioFilter();
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loadingState = false;
        }
      });

    return Promise.resolve();
  }

  open() {
    this.dialogService.openConfirmDialog();
  }


  DeleteSelected(row?: any) {
    this.dialog.open(ConfirmDialogComponent).afterClosed().subscribe(res => {
      if (res) {
        this.loadingState = true;
        let orderId: number;
        orderId = row.id;

        const obj = { account_id: sessionStorage.getItem('account_id'), order_id: orderId };
        // console.log(obj);
        this.http.post(`${this.baseUrl}/delete_order`, obj)
          .subscribe({
            next: async (res: any) => {
              console.log(res);
              await this.FetchOrders();
              this.snackBar.open(`Record deleted.`);
            }, error: (err: any) => {
              console.log(err);
              this.snackBar.open(`An error occurred, please try later`);
            }, complete: () => {
              this.loadingState = false;
            }
          });
      }
    });
  }


  // Filter Functions %%%%%%%%%%%%%%%%%%%%%%%%%%%%
  applyFilter() {
    const filterValue = this.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyRadioFilter() {
    let deliveryTypeId = this.deliveryTypeFilter.value;
    let orderStatusId = this.orderStatusFilter.value;

    // let obj = [[1, 2], [3, 5], [4, 6], [7], [8, 12], [9, 10, 11]];
    let obj = [[1], [3], [12], [7], [8, 12], [9, 10, 11]];
    let filteredData = this.OrderList.filter(x => {
      let filter1 = deliveryTypeId === 0 ? true : (x.delivery_type_id === Number(deliveryTypeId));
      let filter2 = orderStatusId === 0 ? true : (obj[orderStatusId! - 1].includes(x.order_status_id));
      return filter1 && filter2;
    });
    this.dataSource = new MatTableDataSource(filteredData);
    this.dataSource.paginator = this.paginator;
  }

  clearFilter() {
    this.deliveryTypeFilter.setValue(0);
    this.orderStatusFilter.setValue(0);
    this.applyRadioFilter();
  }

  // Selection Functions %%%%%%%%%%%%%%%%%%%%%%%%%
  handleSelect(row: any) {
    this.selection.toggle(row);
    if (this.selection.hasValue()) this.deleteButtonDisabled = false;
    else this.deleteButtonDisabled = true;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.deleteButtonDisabled = true;
      return;
    }
    this.selection.select(...this.dataSource.data);
    this.deleteButtonDisabled = false;
  }

}
