import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface ContactsList {
  fname: string,
  lname: string,
  phone: string,
  email: string,
  address: string,
  state: string,
  city: string,
  country: string,
}


const ELEMENT_DATA: ContactsList[] = [

];

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  @Input() value: string = '';
  displayedColumns: string[] = ['index', 'fname', 'lname', 'phone', 'email', 'address', 'state', 'city', 'country'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = this.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
