<!-- This div with min-height is imp., without which the navbar wont work properly on scroll -->
<div style="min-height: 100vh;">
    <app-navbar></app-navbar>
    <div class="d-flex bg-white py-2">
        <app-sidebar></app-sidebar>
        <div [ngClass]="sidebarStateService.getSidebarState()? 'widthWhenSidebarIsOpened': 'widthWhenSidebarIsClosed' ">
            <mat-card class="container-fluid rounded-2 mat-elevation-z2 p-3" style="min-height: 100vh;">
                <ng-content></ng-content>
            </mat-card>
        </div>
    </div>
    <app-footer></app-footer>
</div>