<app-template>
    <app-breadcrumb>
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item">CRM</li>
        <li class="breadcrumb-item"><a routerLink="/companies">Companies</a></li>
        <li class="breadcrumb-item active">Add Company</li>
    </app-breadcrumb>

    <app-main-heading>Add Company</app-main-heading>
    <form [formGroup]="addCompanyForm" #formDirective="ngForm" (ngSubmit)="onSubmit(formDirective)">
        <div class="row d-flex">
            <div class="col-auto ms-auto">
                <button type="submit" mat-raised-button>Save</button>
            </div>
        </div>

        <div class="row gx-5 mt-3">


            <div class="col-md-6">

                <h6>Personal Details</h6>
                <hr />
                <div class="row gx-4">
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Company Name</mat-label>
                        <input matInput placeholder="Rajesh" formControlName="fname">
                        <mat-icon matSuffix>store</mat-icon>
                        <mat-error *ngIf="addCompanyForm.controls['fname'].hasError('required') ">
                            Company Name is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Company Owner</mat-label>
                        <input matInput placeholder="Patel" formControlName="lname">
                        <mat-icon matSuffix>person</mat-icon>
                        <mat-error *ngIf="addCompanyForm.controls['lname'].hasError('required') ">
                            Owner Name is required
                        </mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Email Address</mat-label>
                        <input matInput placeholder="rajesh.patel@gmail.com" type="email" formControlName="email">
                        <mat-icon matSuffix>email</mat-icon>
                        <mat-error *ngIf="addCompanyForm.controls['email'].hasError('required') ">
                            Email is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Phone No.</mat-label>
                        <input matInput placeholder="8888888888" type="tel" formControlName="phone">
                        <mat-icon matSuffix>phone</mat-icon>
                        <mat-error *ngIf="addCompanyForm.controls['phone'].hasError('required') ">
                            Phone no. is required
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>
            <div class="col-md-6">

                <h6>Other Details</h6>
                <hr />

                <div class="row gx-4">


                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Country</mat-label>
                        <mat-select formControlName="enquiry_mode_id">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let enquiry of EnquiryMode" [value]="enquiry.comm_mode_id">
                                {{enquiry.comm_mode}}</mat-option>

                        </mat-select>
                        <mat-error *ngIf="addCompanyForm.controls['enquiry_mode_id'].hasError('required') ">
                            Country is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>State</mat-label>
                        <mat-select formControlName="enquiry_mode_id">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let enquiry of EnquiryMode" [value]="enquiry.comm_mode_id">
                                {{enquiry.comm_mode}}</mat-option>

                        </mat-select>
                        <mat-error *ngIf="addCompanyForm.controls['enquiry_mode_id'].hasError('required') ">
                            State is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>City</mat-label>
                        <mat-select formControlName="enquiry_mode_id">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let enquiry of EnquiryMode" [value]="enquiry.comm_mode_id">
                                {{enquiry.comm_mode}}</mat-option>

                        </mat-select>
                        <mat-error *ngIf="addCompanyForm.controls['enquiry_mode_id'].hasError('required') ">
                            City is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Pin Code</mat-label>
                        <input matInput placeholder="874521" formControlName="fname">
                    
                        <mat-error *ngIf="addCompanyForm.controls['fname'].hasError('required') ">
                            Pin code is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-12">
                        <mat-label>Company Address</mat-label>
                        <textarea rows="3" matInput placeholder="Enter enquiry details"
                            formControlName="enquiry_details"></textarea>
                        <mat-error *ngIf="addCompanyForm.controls['enquiry_details'].hasError('required') ">
                            Address is required
                        </mat-error>
                    </mat-form-field>

                </div>

            </div>



        </div>
    </form>
</app-template>