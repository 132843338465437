import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Service/auth.service';
import { environment } from 'src/environments/environment';

/*
  Roles Table => [role_id : Role]
  1:  Superadmin
  2:  Admin
  3:  Sales
  4:  Dispatcher
  5:  Delivery Partner
  6:  Customer
*/

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router, private http: HttpClient, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    // By default password field wil be disabled
    this.loginForm.controls['password'].disable();

  }

  loading: boolean = false;
  otpSent: boolean = false;
  loginWithPassword: boolean = false;

  baseUrl = environment.baseUrl;
  public showPassword: boolean = false;

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
    password: new FormControl("", [Validators.required]),
  });



  otpForm = new FormGroup({
    username: new FormControl(''),
    one_time_pass: new FormControl('', [Validators.required]),
    // password: new FormControl('', [Validators.required]),
  });

  handleLoginWithPassword(value: boolean) {
    this.loginWithPassword = value;
    if (value) {
      this.loginForm.controls['password'].enable();
    } else {
      this.loginForm.controls['password'].disable();
    }
    this.loginForm.patchValue({ password: '' });

  }

  sendOtp() {
    if (this.loginForm.valid) {
      this.loginForm.patchValue({ password: '' });
      this.loading = true;
      // console.log(this.loginForm.value);

      this.http.post(`${this.baseUrl}/send_otp`, this.loginForm.value)
        .subscribe({
          next: (res: any) => {
            console.log(res);
            if (res.success) {
              this.snackBar.open("OTP sent successfully");
              this.otpForm.patchValue({ username: this.loginForm.controls['username'].value });
              this.otpSent = true;
            } else {
              console.log(res);
              this.snackBar.open("Please use the registered mobile number");
            }
          }, error: (err: any) => {
            console.log(err);
            this.snackBar.open("Please use the registered mobile number");
            this.loading = false;
          }, complete: () => {
            this.loading = false;
          }
        });
    }
  }


  LoginWithPassword() {
    if (this.loginForm.valid && this.loginForm.controls['password'].value !== '') {
      this.loading = true;
      // console.log(this.loginForm.value);

      this.http.post(`${this.baseUrl}/login`, this.loginForm.value)
        .subscribe({
          next: (res: any) => {
            console.log(res);
            if (res.success) {
              if ([1, 2, 3].includes(res.data.role_id)) { // Only 1,2 role_id are allowed to login 
                this.snackBar.open("Login successful");
                sessionStorage.setItem('user_id', res.data.id);
                sessionStorage.setItem('session_id', res.data.id);
                sessionStorage.setItem('user_email', res.data.email);
                sessionStorage.setItem('role_id', res.data.role_id);
                sessionStorage.setItem('user_full_name', `${res.data.first_name} ${res.data.last_name}`);
                this.router.navigate(['/orders-list']);
              } else {
                this.snackBar.open("Please use the Bombay Medical Stores Mobile App");
              }
            } else {
              console.log(res);
              this.snackBar.open("The username or password is wrong");
            }
          }, error: (err: any) => {
            console.log(err);
            this.snackBar.open("The username or password is wrong");
            this.loading = false;
          }, complete: () => {
            this.loading = false;
          }
        });
    }
  }

  checkOtpAndLogin() {
    if (this.otpForm.valid) {
      this.loading = true;
      console.log(this.otpForm.value);

      this.http.post(`${this.baseUrl}/login_with_otp`, this.otpForm.value)
        .subscribe({
          next: (res: any) => {
            console.log(res);
            if (res.success) {
              if ([1, 2, 3].includes(res.data.role_id)) { // Only 1,2 role_id are allowed to login 
                this.snackBar.open("Login successful");
                sessionStorage.setItem('user_id', res.data.id);
                sessionStorage.setItem('session_id', res.data.id);
                sessionStorage.setItem('user_email', res.data.email);
                sessionStorage.setItem('role_id', res.data.role_id);
                sessionStorage.setItem('user_full_name', `${res.data.first_name} ${res.data.last_name}`);
                this.router.navigate(['/orders-list']);
              } else {
                this.snackBar.open("Please use the Bombay Medical Stores Mobile App");
              }
            } else {
              this.snackBar.open("Otp didn't match, please try again");
            }
          }, error: (err: any) => {
            console.log(err);
            this.snackBar.open("Otp didn't match, please try again");
            this.loading = false;

          }, complete: () => {
            this.loading = false;
          }
        });
    }
  }

  errorMessage(formName: FormGroup, fieldName: string): string {
    const form: FormControl = (formName.get(fieldName) as FormControl);
    return form.hasError('required') ?
      'Field is required' :
      form.hasError('maxlength') ?
        'Must be a valid mobile number' :
        form.hasError('minlength') ?
          'Must be a valid mobile number' : '';
  }

  handleChangeNumber() {
    console.log("hello");
    this.otpForm.patchValue({ one_time_pass: "" });
    this.otpSent = false;
  }

}
