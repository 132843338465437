import { Howl } from 'howler';
export const newOrderSound = () => {
    const sound = new Howl({
        src: ['/assets/sounds/notification-sound.mp3'],
        autoplay: true,
        volume: 1,
    });

    sound.play();
};
