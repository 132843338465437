<app-template>
    <app-breadcrumb>
        <li class="breadcrumb-item active">Help</li>
    </app-breadcrumb>
    <div class="row">
        <div class="col-12">
            <iframe class="w-100" height="700" src="https://portalwiz.net/php/portalwiz_enquiry.html"
            frameborder="0"></iframe>
        </div>
       
    </div>

</app-template>