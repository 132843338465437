import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


export interface InvoiceList {
  invoiceDate: string,
  invoiceAccount: string,
  invoiceStatus: string,
  paidAmount: string,
  orderNo: string,
  orderDate: string,
  orderStatus: string,
  contactName: string,
  contactAddress: string;
}

const ELEMENT_DATA: InvoiceList[] = [
  { invoiceDate: "", invoiceAccount: "", invoiceStatus: "", paidAmount: "", orderNo: "", orderDate: "", orderStatus: "", contactName: "", contactAddress: "" },
  { invoiceDate: "", invoiceAccount: "", invoiceStatus: "", paidAmount: "", orderNo: "", orderDate: "", orderStatus: "", contactName: "", contactAddress: "" },
  { invoiceDate: "", invoiceAccount: "", invoiceStatus: "", paidAmount: "", orderNo: "", orderDate: "", orderStatus: "", contactName: "", contactAddress: "" },

];
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements AfterViewInit {

  @Input() value: string = '';
  displayedColumns: string[] = ['index', 'invoiceDate', 'invoiceAccount', 'invoiceStatus', 'paidAmount', 'orderNo', 'orderDate', 'orderStatus', 'contactName', 'contactAddress'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = this.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
