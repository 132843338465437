import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { OrderData } from 'src/app/Modules/Orders/Order List/order-list/order-list.component';
import { OrderDetailsSidepanelComponent } from 'src/app/Sidepanels/Orders/order-details-sidepanel/order-details-sidepanel.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent {


  pipe = new DatePipe('en-US');
  userId!: string;
  loading: boolean = false;

  // URls
  baseUrl = environment.baseUrl;
  domainUrl = environment.domainUrl;
  storageUrl = environment.storageUrl;
  loadingState: boolean = true;

  @ViewChild(OrderDetailsSidepanelComponent)
  childComponent!: OrderDetailsSidepanelComponent;




  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;


  OrderList: OrderData[] = [];
  tempFilterList: OrderData[] = [];

  MessageList: any = [];


  displayedColumns: string[] = ['ordered_at', 'order_status', 'delivery_mode', 'delivery_type_name', 'actual_delivery_date'];
  dataSource = new MatTableDataSource(this.OrderList);

  editUserForm = new FormGroup({
    user_id: new FormControl(''),
    role_id: new FormControl('', [Validators.required]),
    fname: new FormControl('', [Validators.required]),
    lname: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl(''),
    username: new FormControl('', [Validators.required]),
    dob: new FormControl(''),
    updated_by: new FormControl(''),
  });

  addMessageForm = new FormGroup({
    message: new FormControl(''),
    customer_id: new FormControl(""),
    created_by: new FormControl(sessionStorage.getItem('user_id')),
    user_id: new FormControl(sessionStorage.getItem('user_id')),
  });


  ngOnInit(): void {
    this.userId = this._activatedRoute.snapshot.params["id"];
    this.fetchUserDetails();
    this.fetchOrderDetails();
    this.fetchCustomerChats();



    // Call your function every x milliseconds (e.g., 2000 milliseconds = 2 seconds)
    setInterval(() => {
      this.fetchCustomerChats();
    }, 60000);
  }

  fetchUserDetails() {
    this.snackBar.open('Loading...', undefined, { duration: undefined });
    // this._activatedRoute.paramMap.subscribe(params => {
    //   this.userId = params.get('id')!;
    let obj = { session_id: sessionStorage.getItem('session_id'), user_id: this.userId };
    this.http.post(`${this.baseUrl}/fetch_user`, obj)
      .subscribe({
        next: (res: any) => {
          console.log(res);
          this.editUserForm.patchValue({
            user_id: res[0].id,
            role_id: res[0].role_id,
            fname: res[0].first_name,
            lname: res[0].last_name,
            phone: res[0].phone,
            email: res[0].email,
            username: res[0].username,
            dob: res[0].dob === "0000-00-00" ? null : res[0].dob,
          });
        }, error: (err: any) => {
          console.log(err);
          this.snackBar.open('Error occurred while fetching data, please try later');
        }, complete: () => {
          this.snackBar.dismiss();
        }
      });
    // })
  }

  fetchOrderDetails() {
    // this._activatedRoute.paramMap.subscribe(params => {
    //   this.userId = params.get('id')!;
    let obj = { session_id: sessionStorage.getItem('session_id'), user_id: this.userId };
    this.http.post(`${this.baseUrl}/particular_user_orders`, obj)

      .subscribe({
        next: (data: any) => {
          console.log(data);
          this.OrderList = data;
          this.dataSource = new MatTableDataSource(this.OrderList);
        },
        error: (err: any) => {
          console.log(err);
          this.OrderList = [];
          this.dataSource = new MatTableDataSource(this.OrderList);
          this.loadingState = false;
          this.snackBar.open('Error while fetching enquiries, please try later');
        }, complete: () => {
          // this.applyRadioFilter();
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loadingState = false;
        }
      });

    // })
  }

  fetchCustomerChats(): Promise<any> {
    this.snackBar.open('Loading...', undefined, { duration: undefined });
    // this._activatedRoute.paramMap.subscribe(params => {
    //   this.userId = params.get('id')!;
    let obj = { session_id: sessionStorage.getItem('session_id'), customer_id: this.userId };
    console.log(obj);
    this.http.post(`${this.baseUrl}/fetch_message`, obj)
      .subscribe({
        next: (res: any) => {
          console.log(res);
          this.MessageList = res;
        }, error: (err: any) => {
          console.log(err);
          this.snackBar.open('Error occurred while fetching data, please try later');
        }, complete: () => {
          this.snackBar.dismiss();
          this.cdr.detectChanges(); // Manually trigger change detection
        }
      });
    // });
    return Promise.resolve();
  }



  AddLog() {
    if (this.addMessageForm.valid) {
      this.loading = true;
      this.snackBar.open('Loading...', undefined, { duration: undefined });

      this.addMessageForm.patchValue({ customer_id: this.userId });
      console.log(this.addMessageForm.value);
      this.http.post(`${this.baseUrl}/add_message`, this.addMessageForm.value)
        .subscribe({
          next: (res: any) => {
            console.log(res);
            if (res.success) {
              this.fetchCustomerChats().then(() => {
                // this.addMessageForm.markAsPristine();
                // this.addMessageForm.markAsUntouched();
                // this.addMessageForm.reset();
                this.addMessageForm.patchValue({ message: '' });

                this.snackBar.open('Message added successfully');
              });
            } else {
              console.log(res);
              this.snackBar.open('Error occurred while adding message.');
            }
          }, error: (err: any) => {
            console.log(err);
            this.snackBar.open('Error occurred while adding message.');
          }, complete: () => {
            this.loading = false;
          }
        });
    }
  }

  deleteMessage(id: number) {
    this.snackBar.open('Loading...', undefined, { duration: undefined });

    this.http.post(`${this.baseUrl}/delete_message`, { message_id: id })
      .subscribe({
        next: (res: any) => {
          console.log(res);
          if (res.success) {
            this.fetchCustomerChats().then(() => {
              this.snackBar.open('Message deleted');
            });
          } else {
            console.log(res);
            this.snackBar.open('Error occurred while deleting message.');
          }
        }, error: (err: any) => {
          console.log(err);
          this.snackBar.open('Error occurred while deleting message.');
        }, complete: () => {
          this.loading = false;
        }
      });
  }

  // handleNewTab(id: any) {
  //   window.open(`${this.domainUrl}/edit-order/${id}`);
  // }

  openOrderDetailSidePanel(id: string): void {
    this.childComponent.openPanel(id); // Call the function in the child component
  }
}
