<app-template>
    <!-- <app-breadcrumb>
        <li class="breadcrumb-item active">Profile</li>
    </app-breadcrumb> -->

    <div class="row g-3">
        <div class="col-md-3 d-flex justify-content-center align-items-center">
            <div class="position-relative">
                <img [src]='profilePicturePath' id="profileImage" class="img-fluid rounded-circle position-relative" />
                <button disabled mat-icon-button class="mat-elevation-z2 position-absolute" (click)="EditProfile()"
                    id="editButton">
                    <mat-icon>edit</mat-icon>
                </button>

            </div>

        </div>
        <div class="col-md-6 py-3 text-center text-md-start">
            <h4 class="fw-bold">{{userFullName}}</h4>
            <h6 class="">{{accountDisplayName}} </h6>
            <h6 class="">{{userEmail}}</h6>
            <hr />
            <button (click)="openResetPasswordDialog()" mat-raised-button>Reset Password</button>

        </div>

    </div>
</app-template>