<div class="d-flex flex-column register-gradient justify-content-center align-items-center" style="min-height: 100vh;">
    <div class="card shadow-sm middle-card" style="min-height: 60vh; width:80%;">
        <div class="row d-flex">
            <div class="col-md-6 mt-3">
                <p class=" display-6 mb-3 text-white">Bombay Medical Stores</p>
                <hr style="color: white; width: 15rem;" />
                <p class=" fs-3 text-white" style="letter-spacing: 1.5px; line-height: 1.2;">Register Now,<br /> it's quick and easy
                </p>
                <p class="text-white-50 fw-lighter" style="letter-spacing: 1.5px;">A reliable, easy delivery experience
                    is what you need to excel in today’s <br/> marketplace...
                </p>
            </div>
            <div class="col-md-6">
                <mat-card class=" px-4 pt-4">
                    <form class="register-form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">

                        <div class="row g-3">
                            <div class="col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Full Name</mat-label>
                                    <input type="text" matInput formControlName="name">

                                    <mat-error *ngIf="registerForm.controls['name'].hasError('required') ">
                                        Name is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Phone Number</mat-label>
                                    <input type="tel" matInput formControlName="phone_no">

                                    <mat-error *ngIf="registerForm.controls['phone_no'].hasError('required') ">
                                        Phone no. is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>



                        <mat-form-field appearance="outline" class="pe-3" style="width: 105%;">
                            <mat-label>Email</mat-label>
                            <input type="email" matInput formControlName="email">

                            <mat-error *ngIf="registerForm.controls['email'].hasError('required') ">
                                Email is required
                            </mat-error>
                            <mat-error
                                *ngIf=" !registerForm.controls['email'].hasError('required') && registerForm.controls['email'].hasError('email') ">
                                Please enter a valid email address
                            </mat-error>
                        </mat-form-field>

                        <div class="row g-3">
                            <div class="col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input type="password" matInput formControlName="password">

                                    <mat-error *ngIf="registerForm.controls['password'].hasError('required') ">
                                        Password is required
                                    </mat-error>
                                    <mat-error
                                        *ngIf=" !registerForm.controls['password'].hasError('required') && registerForm.controls['password'].hasError('minlength') ">
                                        Password must be minimum 8 characters long
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Confirm Password</mat-label>
                                    <input type="password" matInput formControlName="confirm_password">

                                    <mat-error *ngIf="registerForm.controls['confirm_password'].hasError('required') ">
                                        Password is required
                                    </mat-error>
                                    <mat-error
                                        *ngIf=" !registerForm.controls['confirm_password'].hasError('required') && registerForm.controls['password'].value !== registerForm.controls['confirm_password'].value ">
                                        Password didn't match
                                    </mat-error>
                                </mat-form-field>

                            </div>
                        </div>






                        <div class="d-block mt-3">
                            <button mat-raised-button class="w-100 register-button" type="submit">Register</button>

                        </div>
                        <p class="text-center mt-3 mb-0">Already have an account? <a routerLink=""
                                class="fw-bold text-decoration-none text-dark">Login</a></p>

                    </form>
                </mat-card>
            </div>
        </div>
    </div>
</div>