<app-template>
    <app-breadcrumb>
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
    <li class="breadcrumb-item">Orders</li>
    <li class="breadcrumb-item active">Payments</li>
</app-breadcrumb>

<app-main-heading>All Payments</app-main-heading>

<div class="row d-flex">
    <div class="col-5 me-auto">
        <mat-form-field class="me-3 w-100">
            <mat-label>Search</mat-label>
            <input matInput type="text" [(ngModel)]="value" (keyup)="applyFilter($event)">
            <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div class="col-auto">
        <!-- <button mat-raised-button>Add</button> -->
        <button disabled mat-raised-button>Add</button>
    </div>
</div>


<div class="row">
    <div class="col-12">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z2 w-100">
            <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef> No. </th>
                <td mat-cell *matCellDef="let element, let i = index"> {{i+1}} </td>
            </ng-container>

            <ng-container matColumnDef="paymentDate">
                <th mat-header-cell *matHeaderCellDef> Payment Date </th>
                <td mat-cell *matCellDef="let element"> {{element.paymentDate}} </td>
            </ng-container>

            <ng-container matColumnDef="transactionRef">
                <th mat-header-cell *matHeaderCellDef> Transaction Ref. </th>
                <td mat-cell *matCellDef="let element"> {{element.transactionRef}} </td>
            </ng-container>

            <ng-container matColumnDef="paymentMode">
                <th mat-header-cell *matHeaderCellDef> Payment Mode </th>
                <td mat-cell *matCellDef="let element"> {{element.paymentMode}} </td>
            </ng-container>

            <ng-container matColumnDef="contactFname">
                <th mat-header-cell *matHeaderCellDef> Contact Fname </th>
                <td mat-cell *matCellDef="let element"> {{element.contactFname}} </td>
            </ng-container>

            <ng-container matColumnDef="contactLname">
                <th mat-header-cell *matHeaderCellDef> Contact Lname </th>
                <td mat-cell *matCellDef="let element"> {{element.contactLname}} </td>
            </ng-container>

            <ng-container matColumnDef="attachments">
                <th mat-header-cell *matHeaderCellDef> Attachments </th>
                <td mat-cell *matCellDef="let element"> {{element.attachments}} </td>
            </ng-container>

            <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef> Notes </th>
                <td mat-cell *matCellDef="let element"> {{element.notes}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="12">No records to display.</td>
            </tr>

        </table>
        <mat-paginator class="mat-elevation-z2" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
            aria-label="Select no. of rows per page">
        </mat-paginator>
    </div>
</div>
</app-template>