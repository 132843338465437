<h2 mat-dialog-title>Edit Profile Picture</h2>
<div mat-dialog-content>
    <p>Choose an image from your device to set as your profile picture</p>

        <input type="file" (change)="onFileSelected($event)"/>
   
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close="false">Close</button>
    <!-- <button disabled mat-raised-button [disabled]="file === undefined ? true: false " (click)="onSubmit()">Update</button> -->
    <button disabled mat-raised-button>Update</button>
</div>