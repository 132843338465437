import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/Dialogs/Common/confirm-dialog/confirm-dialog.component';
import { EditProfilePictureDialogComponent } from 'src/app/Dialogs/Profile/edit-profile-picture-dialog/edit-profile-picture-dialog.component';
import { ResetPasswordDialogComponent } from 'src/app/Dialogs/Profile/reset-password-dialog/reset-password-dialog.component';
import { FetchFunctionsService } from 'src/app/Service/fetch-functions.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  userFullName = sessionStorage.getItem('user_full_name');
  accountDisplayName = sessionStorage.getItem('account_display_name');
  userEmail = sessionStorage.getItem('user_email');

  profilePicturePath = 'assets/Images/avatar.png';

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, public dialog: MatDialog, private fetch: FetchFunctionsService) { }

  ngOnInit(): void {
    this.setProfilePicturePath();
  }

  setProfilePicturePath() {
    this.fetch.FetchProfilePicturePath().subscribe((res: any) => {
      let data = JSON.parse(res.data);
      console.log(data);
      if (res.success && data.profile_path !== '') {
        this.profilePicturePath = `${this.baseUrl}/profile_picture/${data.profile_path}`;
      } else {
        this.profilePicturePath = 'assets/Images/avatar.png';
      }
    });
  }

  openResetPasswordDialog() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Reset Password Confirmation",
        content: "Are you sure you want to reset the password?",
        proceedButtonText: 'Reset'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.dialog.open(ResetPasswordDialogComponent,
          { data: { id: sessionStorage.getItem('session_id') } }
        );
      }
    });
  }

  EditProfile() {

    let dialogRef = this.dialog.open(EditProfilePictureDialogComponent);

    dialogRef.afterClosed().subscribe(res => {
      if (res)
        this.setProfilePicturePath();
    });
  }
}
