<app-template>
    <app-breadcrumb>
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item">CRM</li>
        <li class="breadcrumb-item active">Enquiries</li>
    </app-breadcrumb>

    <app-main-heading>All Enquiries</app-main-heading>

    <!-- <button (click)="open()">Button</button> -->

    <div class="row d-flex">
        <div class="col-5 me-auto">
            <mat-form-field class="me-3 w-100">
                <mat-label>Search</mat-label>
                <input matInput type="text" [(ngModel)]="value" (keyup)="applyFilter($event)">
                <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="value=''; applyFilter($event)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="col-auto">
            <button mat-icon-button [disabled]='deleteButtonDisabled' (click)="DeleteSelected()"
                class="me-3 mat-elevation-z1">
                <mat-icon>delete</mat-icon>
            </button>
            <button routerLink="/add-enquiry" mat-raised-button>Add</button>
        </div>
    </div>

    <div class="row">
        <div class="col-12 ">
            
            <mat-progress-bar *ngIf="loadingState" mode="indeterminate"></mat-progress-bar>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z2 w-100">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell (click)="$event.stopPropagation()" *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? handleSelect(row) : null"
                            [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef> No. </th>
                    <td mat-cell *matCellDef="let element, let i = index"> {{i+1}} </td>
                </ng-container>

                <ng-container matColumnDef="fname">
                    <th mat-header-cell *matHeaderCellDef> First Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.fname}} </td>
                </ng-container>

                <ng-container matColumnDef="lname">
                    <th mat-header-cell *matHeaderCellDef> Last Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.lname}} </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> Phone no. </th>
                    <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                </ng-container>

                <ng-container matColumnDef="enquiry_date">
                    <th mat-header-cell *matHeaderCellDef> Enquiry Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.enquiry_date | date :'mediumDate'}} </td>
                </ng-container>

                <ng-container matColumnDef="enquiry_status_id">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.enquiry_status }} </td>
                </ng-container>

                <ng-container matColumnDef="assigned_to">
                    <th mat-header-cell *matHeaderCellDef> Assigned To </th>
                    <td mat-cell *matCellDef="let element"> {{element.first_name}} {{element.last_name}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Action(s)</th>
                    <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                        <button mat-icon-button (click)="DeleteSelected(element)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [routerLink]="['/edit-enquiry/', row.enquiry_id]"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="15">No records to display.</td>
                </tr>

            </table>
            <mat-paginator class="mat-elevation-z2" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
                aria-label="Select no. of rows per page">
            </mat-paginator>
        </div>
    </div>
</app-template>