import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-profile-picture-dialog',
  templateUrl: './edit-profile-picture-dialog.component.html',
  styleUrls: ['./edit-profile-picture-dialog.component.scss']
})
export class EditProfilePictureDialogComponent {

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private dialogRef: MatDialogRef<EditProfilePictureDialogComponent>) { }

  file: any = undefined;

  onFileSelected(event: any) {
    this.file = event.target.files[0];
  }

  onSubmit() {
    const formData = new FormData();
    formData.append("file", this.file);
    formData.append("session_id", sessionStorage.getItem('session_id')!);


    this.http.post('https://portalwiz.net/php/account/add_profile.php', formData)
      .subscribe((res: any) => {
        console.log(res);
        if (res.success) {
          this.snackBar.open('Profile picture updated successfully.');
          this.dialogRef.close(true);
        } else {
          console.log(res);
          this.dialogRef.close(false);
          this.snackBar.open(res.message);
        }
      });
  }
}
