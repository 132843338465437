import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import getFormData from 'src/app/Common/myFunctions';
import { FetchFunctionsService } from 'src/app/Service/fetch-functions.service';


@Component({
  selector: 'app-add-enquiry',
  templateUrl: './add-enquiry.component.html',
  styleUrls: ['./add-enquiry.component.scss']
})
export class AddEnquiryComponent {
  pipe = new DatePipe('en-US');

  EnquiryType: any = [];
  EnquiryMode: any = [];
  EnquiryStatus: any = [];
  AccountUsers: any = [];

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private fetch: FetchFunctionsService) { }

  ngOnInit(): void {
    this.fetchEnquiryTypes();
    this.fetchEnquiryModes();
    this.fetchEnquiryStatues();
    this.fetchUsers();
  }

  fetchEnquiryTypes() {
    this.fetch.FetchEnquiryTypes().subscribe((res: any) => {
      if (res.success) {
        this.EnquiryType = JSON.parse(res.data);
        console.log(this.EnquiryType);
      } else {
        console.log(res);
        this.snackBar.open('Unable to fetch enquiry types.');
      }
    });
  }

  fetchEnquiryModes() {
    this.fetch.FetchEnquiryModes().subscribe((res: any) => {
      if (res.success) {
        this.EnquiryMode = JSON.parse(res.data);
        console.log(this.EnquiryMode);

      } else {
        console.log(res);
        this.snackBar.open('Unable to fetch enquiry modes.');
      }
    });
  }

  fetchEnquiryStatues() {
    this.fetch.FetchEnquiryStatues().subscribe((res: any) => {
      if (res.success) {
        this.EnquiryStatus = JSON.parse(res.data);
        console.log(this.EnquiryStatus);

      } else {
        console.log(res);
        this.snackBar.open('Unable to fetch enquiry statues.');
      }
    });
  }

  fetchUsers() {
    this.fetch.FetchUsers().subscribe((res: any) => {
      if (res.success) {
        this.AccountUsers = JSON.parse(res.data);
        console.log(this.AccountUsers);

      } else {
        console.log(res);
        this.snackBar.open('Unable to fetch account users.');
      }
    });
  }

  addEnquiryForm = new FormGroup({
    fname: new FormControl('', [Validators.required]),
    lname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required]),
    assigned_to: new FormControl('', [Validators.required]),
    account_id: new FormControl(sessionStorage.getItem('account_id'),),
    enquiry_date: new FormControl(new Date().toISOString(), [Validators.required]),
    enquiry_status_id: new FormControl('', [Validators.required]),
    enquiry_type_id: new FormControl("", [Validators.required]),
    enquiry_mode_id: new FormControl("", [Validators.required]),
    enquiry_details: new FormControl('', [Validators.required]),
  });


  onSubmit(formDirective: FormGroupDirective) {
    if (this.addEnquiryForm.valid) {

      this.snackBar.open('Loading...', undefined, { duration: undefined });

      let formData: FormData = getFormData(this.addEnquiryForm.value);
      console.log(this.addEnquiryForm.value);
      formData.append('session_id', sessionStorage.getItem('session_id')!);
      formData.set('enquiry_date', this.pipe.transform(this.addEnquiryForm.value['enquiry_date'], 'yyyy-MM-dd')!);

      this.http.post('https://portalwiz.net/php/enquiries/add_enquiries.php', formData)
        .subscribe((res: any) => {
          if (res.success) {
            formDirective.resetForm();
            this.addEnquiryForm.reset();
            this.snackBar.open('Enquiry Added');
          } else {
            console.log(res);
            this.snackBar.open('Error occurred while adding enquiry.');
          }
        });

    }

  }
}
