import { Component, OnInit } from '@angular/core';
import { SidebarStateService } from 'src/app/Service/sidebar-state.service';
import tippy from 'tippy.js';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  constructor(public sidebarStateService: SidebarStateService) { }

  LogoUrl = sessionStorage.getItem('logo') !== "" ? 'https://portalwiz.net/' + sessionStorage.getItem('logo') : null;
  accountDisplayName = sessionStorage.getItem('account_display_name');
  roleID = Number(sessionStorage.getItem('role_id'));

  handleTippy(hoverTargetId: string) {
    if (this.sidebarStateService.getSidebarState())
      return;

    const hoverElement = document.getElementById(hoverTargetId);

    const instance = tippy(hoverElement!);

    const menuBlockID = hoverTargetId.concat('-menu');
    const menuBlock = document.getElementById(menuBlockID);
    if (menuBlock !== null) {
      menuBlock.style.display = 'block';
    }
    instance.setContent(menuBlock!);

    instance.setProps({
      allowHTML: true,
      interactive: true,
      duration: 100,
      offset: [0, 5],
      placement: 'right-start',
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['right', 'right-end'],
            },
          },
        ],
      },
    });

    instance.show();
  }
}
