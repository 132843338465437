<app-template>
    <app-breadcrumb>
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item">Orders</li>
        <li class="breadcrumb-item active">Orders list</li>
    </app-breadcrumb>

    <app-main-heading>All Orders</app-main-heading>

    <div class="row g-3 my-3 d-flex">
        <div class="col-12 ">
            <label class="col-md-1">Delivery Type</label>
            <mat-button-toggle-group [formControl]="deliveryTypeFilter" (change)="applyRadioFilter()">
                <mat-button-toggle class="col-md-auto" value='1'>Standard</mat-button-toggle>
                <mat-button-toggle class="col-md-auto" value='2'>Urgent</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="col-12 ">
            <label class="col-md-1">Order Status</label>
            <mat-button-toggle-group [formControl]="orderStatusFilter" (change)="applyRadioFilter()">
                <mat-button-toggle class="col-md-auto" checked="true" value='1'>Open</mat-button-toggle>
                <mat-button-toggle class="col-md-auto" value='2'>Packing In Progress</mat-button-toggle>
                <mat-button-toggle class="col-md-auto" value='3'>Completed</mat-button-toggle>
                <!-- <mat-button-toggle class="col-md-auto" value='4'>Out For Delivery</mat-button-toggle>
                <mat-button-toggle class="col-md-auto" value='5'>Delivered</mat-button-toggle>
                <mat-button-toggle class="col-md-auto" value='6'>Other</mat-button-toggle> -->
            </mat-button-toggle-group>
        </div>

    </div>

    <div class="row mt-3 mb-3 mb-md-0 d-flex justify-content-between">
        <div class="col-12 col-md-5 order-1 order-md-0">
            <mat-form-field class="me-3 w-100">
                <mat-label>Search</mat-label>
                <input matInput type="text" [(ngModel)]="value" (keyup)="applyFilter()">
                <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''; applyFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="col-auto ms-auto">
            <button (click)="clearFilter()" mat-raised-button>Clear Filter</button>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <mat-progress-bar *ngIf="loadingState" mode="indeterminate"></mat-progress-bar>
            <div class="mat-elevation-z2">
                <div class="table-responsive">
                    <table class="w-100" mat-table [dataSource]="dataSource" matSort>
                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell (click)="$event.stopPropagation()" *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? handleSelect(row) : null" [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="index">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
                        <td mat-cell *matCellDef="let element, let i = index"> {{i+1}} </td>
                    </ng-container> -->

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> Action(s)</th>
                            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                                <button mat-icon-button (click)="DeleteSelected(element)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No. </th>
                            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="ordered_at">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ordered At </th>
                            <td mat-cell *matCellDef="let element"> {{element.ordered_at | date: 'MMM d, y, h:mm a'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="order_status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                            <td mat-cell *matCellDef="let element"> {{element.order_status}} </td>
                        </ng-container>

                        <ng-container matColumnDef="first_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.first_name}} {{element.last_name}} </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="last_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
                    </ng-container> -->
                        <ng-container matColumnDef="delivery_mode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Mode </th>
                            <td mat-cell *matCellDef="let element"> {{element.delivery_mode}} </td>
                        </ng-container>
                        <ng-container matColumnDef="delivery_type_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Type </th>
                            <td mat-cell *matCellDef="let element"> {{element.delivery_type_name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="assigned_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Assigned To </th>
                            <td mat-cell *matCellDef="let element"> {{element.assigned_name === null ? '--' :
                                element.assigned_name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="actual_delivery_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Delivery Date </th>
                            <td mat-cell *matCellDef="let element"> {{element.actual_delivery_date === null ?
                                '--':element.actual_delivery_date | date: 'MMM d, y, h:mm a'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="dispatcher_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatcher</th>
                            <td mat-cell *matCellDef="let element"> {{element.dispatcher_name === null ? '--' :
                                element.dispatcher_name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="delivery_partner_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Partner </th>
                            <td mat-cell *matCellDef="let element"> {{element.delivery_partner_name === null ? '--' :
                                element.delivery_partner_name}} </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            [routerLink]="['/edit-order/', row.id]">
                        </tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No records to display.</td>
                        </tr>

                    </table>
                </div>

                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
                    aria-label="Select no. of rows per page">
                </mat-paginator>
            </div>
        </div>
    </div>
</app-template>