<app-template>
    <app-breadcrumb>
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item">CRM</li>
        <li class="breadcrumb-item"><a routerLink="/enquiries">Enquiries</a></li>
        <li class="breadcrumb-item active">Edit Enquiry</li>
    </app-breadcrumb>

    <app-main-heading>Edit Enquiry</app-main-heading>

    <form [formGroup]="editEnquiryForm" #formDirective="ngForm" (ngSubmit)="onSubmit(formDirective)">
        <div class="row d-flex">
            <div class="col-auto ms-auto">
                <button (click)="DeleteEnquiry()" class="btn btn-danger me-3" mat-raised-button>Delete </button>
                <button type="submit" mat-raised-button>Save </button>
            </div>
        </div>

        <div class="row gx-5 mt-3">


            <div class="col-md-6">

                <h6>Personal Details</h6>
                <hr />
                <div class="row gx-4">
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>First Name</mat-label>
                        <input matInput placeholder="Rajesh" formControlName="fname">
                        <mat-icon matSuffix>person</mat-icon>
                        <mat-error *ngIf="editEnquiryForm.controls['fname'].hasError('required') ">
                            First Name is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Patel" formControlName="lname">
                        <mat-icon matSuffix>person</mat-icon>
                        <mat-error *ngIf="editEnquiryForm.controls['lname'].hasError('required') ">
                            Last Name is required
                        </mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Email Address</mat-label>
                        <input matInput placeholder="rajesh.patel@gmail.com" type="email" formControlName="email">
                        <mat-icon matSuffix>email</mat-icon>
                        <mat-error *ngIf="editEnquiryForm.controls['email'].hasError('required') ">
                            Email is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Phone No.</mat-label>
                        <input matInput placeholder="8888888888" type="tel" formControlName="phone">
                        <mat-icon matSuffix>phone</mat-icon>
                        <mat-error *ngIf="editEnquiryForm.controls['phone'].hasError('required') ">
                            Phone no. is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>


            <div class="col-md-6 ">

                <h6>Enquiry Details</h6>
                <hr />

                <div class="row gx-4">

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Enquiry Type</mat-label>
                        <mat-select formControlName="enquiry_type_id">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let enquiry of EnquiryType" [value]="enquiry.enquiry_type_id">
                                {{enquiry.enquiry_type}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="editEnquiryForm.controls['enquiry_type_id'].hasError('required') ">
                            Enquiry type is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6 ">
                        <mat-label>Enquiry Mode</mat-label>
                        <mat-select formControlName="enquiry_mode_id">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let enquiry of EnquiryMode" [value]="enquiry.comm_mode_id">
                                {{enquiry.comm_mode}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="editEnquiryForm.controls['enquiry_mode_id'].hasError('required') ">
                            Enquiry mode is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-12">
                        <mat-label>Enquiry Details</mat-label>
                        <textarea rows="3" matInput placeholder="Enter enquiry details"
                            formControlName="enquiry_details"></textarea>
                        <mat-error *ngIf="editEnquiryForm.controls['enquiry_details'].hasError('required') ">
                            Enquiry details is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Enquiry Date</mat-label>
                        <input matInput formControlName="enquiry_date" [matDatepicker]="picker" placeholder="MM/DD/YYYY">
                        <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6 ">
                        <mat-label>Enquiry Status</mat-label>
                        <mat-select formControlName="enquiry_status_id">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let enquiry of EnquiryStatus" [value]="enquiry.enquiry_status_id">
                                {{enquiry.enquiry_status}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="editEnquiryForm.controls['enquiry_type_id'].hasError('required') ">
                            Enquiry status is required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Assign To</mat-label>
                        <mat-select formControlName="assigned_to">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let user of AccountUsers" [value]="user.user_id">
                                {{user.first_name}} {{user.last_name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="editEnquiryForm.controls['assigned_to'].hasError('required') ">
                            This field is required
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>



        </div>
    </form>
</app-template>