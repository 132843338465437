import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, content: string, proceedButtonText: string; }) { }

  title: string | null = this.data && (this.data.title ?? null);
  content: string | null = this.data && (this.data.content ?? null);
  proceedButtonText: string | null = this.data && (this.data.proceedButtonText ?? null);

}
