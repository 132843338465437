<nav class="navbar container-fluid navbar-light sticky-top mat-elevation-z1 navbarStyle" style="z-index: 500;">
  <button mat-icon-button color="white" class="navbar-button mx-2 mat-elevation-z2" style="padding: 10px;"
    (click)="toggleSidebar()">
    <mat-icon>{{ sidebarStateService.getSidebarState()? 'arrow_back': 'menu' }}</mat-icon>
  </button>

  <p class="btn mb-0 me-auto navBrand" style="font-size: 1.3rem;">Bombay Medical Stores</p>

  <div class="d-flex align-items-center">

    <p class="mb-0 fs-6 me-2 d-none d-md-block" style="font-family: Heebo;">{{userFullName}}</p>
    <ul class="navbar-nav ">
      <li class="nav-item dropdown ">

        <button mat-icon-button class="dropdown-button mx-2 mat-elevation-z2" style="padding: 10px;"
          id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <mat-icon>account_circle</mat-icon>
          <!-- <img [src]='profilePicturePath' id="profileImage"
            class="img-fluid rounded-circle position-relative" /> -->
        </button>
        <div class="dropdown-menu dropdown-menu-end mat-elevation-z3 border-0 position-absolute "
          aria-labelledby="navbarDropdownMenuLink">
          <button class="dropdown-item d-flex align-items-center py-2" (click)='logout()'>
            <mat-icon class="me-4">exit_to_app</mat-icon><span>Logout</span>
          </button>

          <a routerLink="/profile" class="dropdown-item d-flex align-items-center py-2">
            <mat-icon class="me-4">person_outline</mat-icon><span>Profile</span>
          </a>
          <mat-divider></mat-divider>
          <mat-list>

            <mat-list-item>
              <mat-icon matListItemIcon class="me-4">account_circle</mat-icon>
              <span matListItemTitle>{{userFullName}}</span>
            </mat-list-item>

          </mat-list>
        </div>
      </li>
    </ul>
  </div>
</nav>