<app-template>
    <app-breadcrumb>
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item">Setup</li>
        <li class="breadcrumb-item"><a routerLink="/users">Users</a></li>
        <li class="breadcrumb-item active">User Details</li>

    </app-breadcrumb>

    <!-- <app-main-heading>User Details</app-main-heading> -->
    <form [formGroup]="editUserForm" id="editUserForm" (ngSubmit)="onSubmit()">


        <div class="row d-flex">
            <div class="col-auto">
                <button type="button" (click)="handleResetPassword()" mat-raised-button>Reset Password </button>

            </div>
            <div class="col-auto ms-auto">
                <button type="button" (click)="handleDelete()" class="me-3" mat-raised-button>Delete
                </button>
                <button type="submit" color="primary" mat-raised-button>Save </button>
            </div>
        </div>
        <hr />

        <div class="row">
            <div class="col">
                <div class="alert alert-primary" role="alert">For <strong>logging in</strong>, the <strong>Phone Number
                    </strong> entered in the <strong>Username field</strong>
                    will be used. <strong>An OTP will be sent to the same number for logging in</strong>.
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">

                <div class="row gx-4">
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>First Name</mat-label>
                        <input readonly matInput formControlName="fname">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Last Name</mat-label>
                        <input readonly matInput formControlName="lname">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Email Address</mat-label>
                        <input readonly matInput type="email" formControlName="email">
                    </mat-form-field>


                    <mat-form-field appearance="outline" class="col-md-6 mb-3">
                        <mat-label>Username</mat-label>
                        <input readonly matInput placeholder="8888888888" type="tel" formControlName="username">
                        <mat-hint>User's Primary Phone Number will come here</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Phone No.</mat-label>
                        <input readonly matInput placeholder="8888888888" type="tel" formControlName="phone">
                    </mat-form-field>


                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Date Of Birth</mat-label>
                        <input readonly matInput formControlName="dob" [matDatepicker]="dob_picker"
                            placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle matIconSuffix [for]="dob_picker"></mat-datepicker-toggle>
                        <mat-datepicker #dob_picker></mat-datepicker>
                    </mat-form-field>

                    <!-- <mat-form-field appearance="outline" class="col-md-12">
                        <mat-label>Address</mat-label>
                        <textarea readonly rows="2" matInput matInput formControlName="address"></textarea>
                    </mat-form-field> -->

                </div>
            </div>
            <div class="col-md-6 ">
                <div class="row gx-4">


                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Role</mat-label>
                        <mat-select formControlName="role_id">
                            <mat-option *ngFor="let item of roleList" [value]="item['role_id']">
                                {{item['name']}} </mat-option>
                        </mat-select>
                        <mat-error *ngIf="editUserForm.controls['role_id'].hasError('required') ">
                            Field is required
                        </mat-error>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>State</mat-label>
                        <input readonly matInput formControlName="state">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>City</mat-label>
                        <input readonly matInput formControlName="city">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Pin Code</mat-label>
                        <input readonly matInput formControlName="pin">
                    </mat-form-field> -->

                </div>
            </div>
        </div>
    </form>


</app-template>