import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './Auth/forgot-password/forgot-password.component';
import { LoginComponent } from './Auth/login/login.component';
import { RegisterComponent } from './Auth/register/register.component';
import { ResetPasswordComponent } from './Auth/reset-password/reset-password.component';
import * as pageNames from './Common/pageNames';
import { authGuard } from './Guard/auth.guard';
import { AddCompanyComponent } from './Modules/Crm/Company/add-company/add-company.component';
import { CompanyComponent } from './Modules/Crm/Company/company/company.component';
import { EditCompanyComponent } from './Modules/Crm/Company/edit-company/edit-company.component';
import { AddContactComponent } from './Modules/Crm/Contact/add-contact/add-contact.component';
import { ContactComponent } from './Modules/Crm/Contact/contact/contact.component';
import { EditContactComponent } from './Modules/Crm/Contact/edit-contact/edit-contact.component';
import { AddEnquiryComponent } from './Modules/Crm/Enquiry/add-enquiry/add-enquiry.component';
import { EditEnquiryComponent } from './Modules/Crm/Enquiry/edit-enquiry/edit-enquiry.component';
import { EnquiryComponent } from './Modules/Crm/Enquiry/enquiry/enquiry.component';
import { DashboardComponent } from './Modules/Dashboard/dashboard/dashboard.component';
import { UserHelpComponent } from './Modules/Common/user-help/user-help.component';
import { DispatchQueueComponent } from './Modules/Orders/dispatch-queue/dispatch-queue.component';
import { InvoiceComponent } from './Modules/Orders/invoice/invoice.component';
import { EditOrderComponent } from './Modules/Orders/Order List/edit-order/edit-order.component';
import { OrderListComponent } from './Modules/Orders/Order List/order-list/order-list.component';
import { PaymentComponent } from './Modules/Orders/payment/payment.component';
import { ProfileComponent } from './Modules/Profile/profile/profile.component';
import { ReleaseNotesComponent } from './Modules/Common/release-notes/release-notes.component';
import { OrderStatusComponent } from './Modules/Setup/order-status/order-status.component';
import { PermissionComponent } from './Modules/Setup/permission/permission.component';
import { RoleComponent } from './Modules/Setup/role/role.component';
import { UserComponent } from './Modules/Setup/Users/user/user.component';
import { SystemAdminPanelComponent } from './Modules/Common/system-admin-panel/system-admin-panel.component';
import { ManageTeamComponent } from './Modules/Teams/manage-team/manage-team.component';
import { UserDetailsComponent } from './Modules/Setup/Users/user-details/user-details.component';
import { CustomersComponent } from './Modules/Setup/Customers/customers/customers.component';
import { CustomerDetailsComponent } from './Modules/Setup/Customers/customer-details/customer-details.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [authGuard], title: pageNames.DASHBOARD },

  // Auth
  { path: 'login', component: LoginComponent, title: pageNames.LOGIN },
  { path: 'register', component: RegisterComponent, title: pageNames.REGISTER },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'register-password', component: ResetPasswordComponent },

  // General
  { path: 'help', canActivate: [authGuard], component: UserHelpComponent, title: pageNames.HELP },
  { path: 'profile', canActivate: [authGuard], component: ProfileComponent, title: pageNames.PROFILE },
  { path: 'release-notes', canActivate: [authGuard], component: ReleaseNotesComponent },
  { path: 'system-admin-panel', canActivate: [authGuard], component: SystemAdminPanelComponent },

  // Crm
  { path: 'companies', canActivate: [authGuard], component: CompanyComponent, title: pageNames.COMPANIES },
  { path: 'add-company', canActivate: [authGuard], component: AddCompanyComponent, title: pageNames.ADD_COMPANY },
  { path: 'edit-company/:id', canActivate: [authGuard], component: EditCompanyComponent, title: pageNames.EDIT_COMPANY },
  { path: 'contacts', canActivate: [authGuard], component: ContactComponent, title: pageNames.CONTACTS },
  { path: 'add-contact', canActivate: [authGuard], component: AddContactComponent, title: pageNames.ADD_CONTACTS },
  { path: 'edit-contact/:id', canActivate: [authGuard], component: EditContactComponent, title: pageNames.EDIT_CONTACTS },
  { path: 'enquiries', canActivate: [authGuard], component: EnquiryComponent, title: pageNames.ENQUIRIES },
  { path: 'add-enquiry', canActivate: [authGuard], component: AddEnquiryComponent, title: pageNames.ADD_ENQUIRY },
  { path: 'edit-enquiry/:id', canActivate: [authGuard], component: EditEnquiryComponent, title: pageNames.EDIT_ENQUIRY },

  // Orders
  { path: 'dispatch-queues', canActivate: [authGuard], component: DispatchQueueComponent },
  { path: 'invoices', canActivate: [authGuard], component: InvoiceComponent },
  { path: 'orders-list', canActivate: [authGuard], component: OrderListComponent, title: pageNames.ALL_ORDERS },
  { path: 'edit-order/:id', canActivate: [authGuard], component: EditOrderComponent, title: pageNames.EDIT_ORDER },
  { path: 'payments', canActivate: [authGuard], component: PaymentComponent },

  // Setup
  { path: 'order-statuses', canActivate: [authGuard], component: OrderStatusComponent },
  { path: 'permissions', canActivate: [authGuard], component: PermissionComponent },
  { path: 'roles', canActivate: [authGuard], component: RoleComponent },
  { path: 'customers', canActivate: [authGuard], component: CustomersComponent },
  { path: 'customer-details/:id', canActivate: [authGuard], component: CustomerDetailsComponent },

  // Users
  { path: 'users', canActivate: [authGuard], component: UserComponent },
  { path: 'user-details/:id', canActivate: [authGuard], component: UserDetailsComponent },

  // Teams
  { path: 'team-list', canActivate: [authGuard], component: ManageTeamComponent },

  // // Folder Name
  // { path: '', component: ProfileComponent },
  // { path: '', component: ProfileComponent },
  // { path: '', component: ProfileComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
