import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FetchFunctionsService } from 'src/app/Service/fetch-functions.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AddUserDialogComponent>,
    private fetch: FetchFunctionsService) { }

  ngOnInit(): void {
    this.fetchRoles();
  }

  pipe = new DatePipe('en-US');

  baseUrl = environment.baseUrl;
  loading: boolean = false;
  roleList = [];


  addUserForm = new FormGroup({
    fname: new FormControl('', [Validators.required]),
    lname: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    dob: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    role: new FormControl(null, [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    confirmPassword: new FormControl('', [Validators.required]),
    created_by: new FormControl(sessionStorage.getItem('user_id')),
  });

  checkConfirmPassword() {
    return (this.addUserForm.controls.password.value === this.addUserForm.controls.confirmPassword.value ? true : false);
  }

  fetchRoles() {
    this.fetch.FetchRoles().subscribe((res: any) => {
      // console.log(res);
      this.roleList = res.filter((x: any) => x.role_id !== 1);
    });
  }

  onSubmit() {
    if (this.addUserForm.valid && this.checkConfirmPassword()) {
      this.loading = true;

      let obj = this.addUserForm.value;
      obj.dob = this.pipe.transform(this.addUserForm.controls['dob'].value, 'yyyy-MM-dd');
      console.log(obj);
      this.http.post(`${this.baseUrl}/add_user`, obj)
        .subscribe({
          next: (res: any) => {
            console.log(res);
            if (res.success) {
              this.dialogRef.close(true);
            } else {
              console.log(res);
              this.snackBar.open('Error occurred while adding user.');
              this.dialogRef.close(false);
            }
          }, error: (err: any) => {
            console.log(err);
            this.snackBar.open('Error occurred while adding user.');
            this.dialogRef.close(false);
          }, complete: () => {
            this.loading = false;
          }
        });
    } else {
      this.snackBar.open("Confirm Password didn't match");
    }
  }
}
