import { inject } from "@angular/core";
import { Router } from '@angular/router';

export const authGuard = () => {
  const router = inject(Router);

  // Check if the user is logged in.
  if (sessionStorage.getItem('user_id')) {
    // The user is logged in, so allow them to proceed.
    return true;
  } else {
    // Redirect to the login page.
    return router.parseUrl('/login');
  }
};

