import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  EnquiryData = {
    pending: 0, total: 0, resolved: 0, invoice_generated: 0, completed: 0, thisWeek: 0
  };

  ngOnInit(): void {
    this.fetchDashboardCounts();
  }

  fetchDashboardCounts() {
    this.snackBar.open('Loading...', undefined, { duration: undefined });

    this.http.get(`${this.baseUrl}/dashboard`)
      .subscribe({
        next: (res: any) => {
          console.log(res);
          this.EnquiryData = {
            pending: res.pending, total: 0, resolved: 0,
            invoice_generated: res.invoice_generated, completed: res.completed,
            thisWeek: res.week_order
          };
          this.snackBar.dismiss();

        }, error: ((err: any) => {
          console.log(err);
          this.snackBar.open('An error occurred, please try later');

        })

      });
  }
}
