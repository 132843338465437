<div id="sidebar" class="rounded-2 shadow " [ngClass]="sidebarStateService.getSidebarState()? 'active': '' ">

    <!-- <div id="sidebarHeader" *ngIf="LogoUrl !== null" class="d-flex justify-content-center "
        [ngClass]="sidebarStateService.getSidebarState()? 'active': '' ">
        <img [src]='LogoUrl' class="img-fluid " />

    </div>

    <div id="accountDisplayName" *ngIf="accountDisplayName !== ''" class="bg-white ps-3 py-2">
        <h4 class="fw-bold p-0 m-0">{{accountDisplayName}}</h4>
    </div> -->

    <div id="sidebarContent" [ngClass]="sidebarStateService.getSidebarState()? 'active': '' ">
        <ul class="list-unstyled">
            <li><a routerLink="/">
                    <mat-icon>grid_view</mat-icon><span>Dashboard</span>
                </a></li>

            <!-- CRM -->
            <!-- <li (mouseenter)="handleTippy('crm-tippy')" id="crm-tippy"><a data-bs-toggle="collapse" href="#crm">
                    <mat-icon>headset_mic</mat-icon><span>CRM<mat-icon>arrow_drop_down</mat-icon></span>
                </a></li>
            <ul class="collapse list-unstyled sub-menu" id="crm">
                <li><a routerLink="/enquiries">
                        <mat-icon>arrow_right</mat-icon>Enquiries
                    </a></li>
                <li><a routerLink="/companies">
                        <mat-icon>arrow_right</mat-icon>Companies
                    </a></li>
                <li><a routerLink="/contacts">
                        <mat-icon>arrow_right</mat-icon>Contacts
                    </a></li>
            </ul>
            <div id="crm-tippy-menu" class="hover-sub-menu">
                <ul class="list-unstyled">
                    <li><span>CRM</span></li>
                    <li><a routerLink="/enquiries">
                            <mat-icon>arrow_right</mat-icon>Enquiries
                        </a></li>
                    <li><a routerLink="/companies">
                            <mat-icon>arrow_right</mat-icon>Companies
                        </a></li>
                    <li><a routerLink="/contacts">
                            <mat-icon>arrow_right</mat-icon>Contacts
                        </a></li>
                </ul>
            </div> -->

            <!-- Orders -->
            <li (mouseenter)="handleTippy('orders-tippy')" id="orders-tippy"><a data-bs-toggle="collapse"
                    href="#orders">
                    <mat-icon>receipt_long</mat-icon><span>Orders<mat-icon>arrow_drop_down</mat-icon></span>
                </a></li>
            <ul class="collapse list-unstyled sub-menu" id="orders">
                <li><a routerLink="/orders-list"><mat-icon>arrow_right</mat-icon>Orders List </a></li>
                <li><a routerLink="/customers"><mat-icon>arrow_right</mat-icon>Customers</a></li>

                <!-- <li><a routerLink="/invoices">
                        <mat-icon>arrow_right</mat-icon>Invoices
                    </a></li>
                <li><a routerLink="/payments">
                        <mat-icon>arrow_right</mat-icon>Payments
                    </a></li>
                <li><a routerLink="/dispatch-queues">
                        <mat-icon>arrow_right</mat-icon>Dispatch Queues
                    </a></li> -->
            </ul>
            <div id="orders-tippy-menu" class="hover-sub-menu">
                <ul class="list-unstyled">
                    <li><span>Orders</span></li>
                    <li><a routerLink="/orders-list"><mat-icon>arrow_right</mat-icon>Orders List </a></li>
                    <li><a routerLink="/customers"><mat-icon>arrow_right</mat-icon>Customers</a></li>

                    <!-- <li><a routerLink="/invoices">
                            <mat-icon>arrow_right</mat-icon>Invoices
                        </a></li>
                    <li><a routerLink="/payments">
                            <mat-icon>arrow_right</mat-icon>Payments
                        </a></li>
                    <li><a routerLink="/dispatch-queues">
                            <mat-icon>arrow_right</mat-icon>Dispatch Queues
                        </a></li> -->
                </ul>
            </div>

            <!-- Teams -->
            <!-- <li (mouseenter)="handleTippy('teams-tippy')" id="teams-tippy"><a data-bs-toggle="collapse" href="#teams"><mat-icon>groups</mat-icon><span>Teams<mat-icon>arrow_drop_down</mat-icon></span></a></li>
           <ul class="collapse list-unstyled sub-menu" id="teams">
                <li><a routerLink="/team-list" ><mat-icon>arrow_right</mat-icon>Manage Teams</a></li>
            </ul>
           <div id="teams-tippy-menu" class="hover-sub-menu">
            <ul class="list-unstyled" >
                <li><span>Teams</span></li>
                <li><a routerLink="/team-list" ><mat-icon>arrow_right</mat-icon>Manage Teams</a></li>
            </ul>
           </div> -->

            <!-- Setup -->
            <li *ngIf="roleID !==3" (mouseenter)="handleTippy('setup-tippy')" id="setup-tippy"><a
                    data-bs-toggle="collapse"
                    href="#setup"><mat-icon>settings</mat-icon><span>Setup<mat-icon>arrow_drop_down</mat-icon></span></a>
            </li>
            <ul class="collapse list-unstyled sub-menu" id="setup">
                <li><a routerLink="/users"><mat-icon>arrow_right</mat-icon>Users</a></li>
                <!-- <li><a routerLink="/roles"><mat-icon>arrow_right</mat-icon>Roles</a></li>
                <li><a routerLink="/permissions"><mat-icon>arrow_right</mat-icon>Permissions</a></li>
                <li><a routerLink="/order-statuses"><mat-icon>arrow_right</mat-icon>Order Statuses</a></li> -->
            </ul>
            <div id="setup-tippy-menu" class="hover-sub-menu">
                <ul class="list-unstyled">
                    <li><span>Setup</span></li>
                    <li><a routerLink="/users"><mat-icon>arrow_right</mat-icon>Users</a></li>
                    <!-- <li><a routerLink="/roles"><mat-icon>arrow_right</mat-icon>Roles</a></li>
                    <li><a routerLink="/permissions"><mat-icon>arrow_right</mat-icon>Permissions</a></li>
                    <li><a routerLink="/order-statuses"><mat-icon>arrow_right</mat-icon>Order Statuses</a></li> -->
                </ul>
            </div>

            <li><a routerLink="/help">
                    <mat-icon>help</mat-icon><span>Help</span>
                </a></li>

        </ul>

    </div>

</div>