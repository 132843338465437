import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LightboxModule } from 'ngx-lightbox';
import { ForgotPasswordComponent } from './Auth/forgot-password/forgot-password.component';
import { LoginComponent } from './Auth/login/login.component';
import { RegisterComponent } from './Auth/register/register.component';
import { ResetPasswordComponent } from './Auth/reset-password/reset-password.component';
import { BreadcrumbComponent } from './Common/breadcrumb/breadcrumb.component';
import { FooterComponent } from './Common/footer/footer.component';
import { MainHeadingComponent } from './Common/main-heading/main-heading.component';
import { NavbarComponent } from './Common/navbar/navbar.component';
import { SectionHeadingComponent } from './Common/section-heading/section-heading.component';
import { SidebarComponent } from './Common/sidebar/sidebar.component';
import { TemplateComponent } from './Common/template/template.component';
import { ConfirmDialogComponent } from './Dialogs/Common/confirm-dialog/confirm-dialog.component';
import { EditProfilePictureDialogComponent } from './Dialogs/Profile/edit-profile-picture-dialog/edit-profile-picture-dialog.component';
import { ResetPasswordDialogComponent } from './Dialogs/Profile/reset-password-dialog/reset-password-dialog.component';
import { AddUserDialogComponent } from './Dialogs/Setup/Users/add-user-dialog/add-user-dialog.component';
import { EditUserDialogComponent } from './Dialogs/Setup/Users/edit-user-dialog/edit-user-dialog.component';
import { ReleaseNotesComponent } from './Modules/Common/release-notes/release-notes.component';
import { SystemAdminPanelComponent } from './Modules/Common/system-admin-panel/system-admin-panel.component';
import { UserHelpComponent } from './Modules/Common/user-help/user-help.component';
import { AddCompanyComponent } from './Modules/Crm/Company/add-company/add-company.component';
import { CompanyComponent } from './Modules/Crm/Company/company/company.component';
import { EditCompanyComponent } from './Modules/Crm/Company/edit-company/edit-company.component';
import { AddContactComponent } from './Modules/Crm/Contact/add-contact/add-contact.component';
import { ContactComponent } from './Modules/Crm/Contact/contact/contact.component';
import { EditContactComponent } from './Modules/Crm/Contact/edit-contact/edit-contact.component';
import { AddEnquiryComponent } from './Modules/Crm/Enquiry/add-enquiry/add-enquiry.component';
import { EditEnquiryComponent } from './Modules/Crm/Enquiry/edit-enquiry/edit-enquiry.component';
import { EnquiryComponent } from './Modules/Crm/Enquiry/enquiry/enquiry.component';
import { DashboardComponent } from './Modules/Dashboard/dashboard/dashboard.component';
import { EditOrderComponent } from './Modules/Orders/Order List/edit-order/edit-order.component';
import { OrderListComponent } from './Modules/Orders/Order List/order-list/order-list.component';
import { DispatchQueueComponent } from './Modules/Orders/dispatch-queue/dispatch-queue.component';
import { InvoiceComponent } from './Modules/Orders/invoice/invoice.component';
import { PaymentComponent } from './Modules/Orders/payment/payment.component';
import { ProfileComponent } from './Modules/Profile/profile/profile.component';
import { CustomerDetailsComponent } from './Modules/Setup/Customers/customer-details/customer-details.component';
import { CustomersComponent } from './Modules/Setup/Customers/customers/customers.component';
import { UserDetailsComponent } from './Modules/Setup/Users/user-details/user-details.component';
import { UserComponent } from './Modules/Setup/Users/user/user.component';
import { OrderStatusComponent } from './Modules/Setup/order-status/order-status.component';
import { PermissionComponent } from './Modules/Setup/permission/permission.component';
import { RoleComponent } from './Modules/Setup/role/role.component';
import { ManageTeamComponent } from './Modules/Teams/manage-team/manage-team.component';
import { OrderDetailsSidepanelComponent } from './Sidepanels/Orders/order-details-sidepanel/order-details-sidepanel.component';
import { AngularMaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    BreadcrumbComponent,
    FooterComponent,
    MainHeadingComponent,
    NavbarComponent,
    SectionHeadingComponent,
    SidebarComponent,
    TemplateComponent,
    ConfirmDialogComponent,
    EditProfilePictureDialogComponent,
    ResetPasswordDialogComponent,
    AddUserDialogComponent,
    EditUserDialogComponent,
    OrderDetailsSidepanelComponent,
    UserHelpComponent,
    ReleaseNotesComponent,
    SystemAdminPanelComponent,
    AddCompanyComponent,
    CompanyComponent,
    EditCompanyComponent,
    AddContactComponent,
    ContactComponent,
    EditContactComponent,
    AddEnquiryComponent,
    EditEnquiryComponent,
    EnquiryComponent,
    DashboardComponent,
    DispatchQueueComponent,
    InvoiceComponent,
    PaymentComponent,
    EditOrderComponent,
    OrderListComponent,
    ProfileComponent,
    CustomersComponent,
    OrderStatusComponent,
    PermissionComponent,
    RoleComponent,
    UserComponent,
    UserDetailsComponent,
    ManageTeamComponent,
    CustomerDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    FormsModule,
    HttpClientModule,
    LightboxModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
