<app-template>
    <app-order-details-sidepanel></app-order-details-sidepanel>

    <app-breadcrumb>
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item">Setup</li>
        <li class="breadcrumb-item"><a routerLink="/customers">Customers</a></li>
        <li class="breadcrumb-item active">Customer Details</li>

    </app-breadcrumb>

    <!-- <app-main-heading>User Details</app-main-heading> -->
    <form [formGroup]="editUserForm" id="editUserForm">
        <!-- <div class="row d-flex">
            <div class="col-auto">
                <button type="button" (click)="handleResetPassword()" mat-raised-button>Reset Password </button>

            </div>
            <div class="col-auto ms-auto">
                <button type="button" (click)="handleDelete()" class="me-3" mat-raised-button>Delete
                </button>
                <button type="submit" color="primary" mat-raised-button>Save </button>
            </div>
        </div>
        <hr /> -->
        <div class="row">

            <div class="col-md-6">
                <app-section-heading>Customer Details</app-section-heading>

                <div class="row gx-4">
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>First Name</mat-label>
                        <input readonly matInput formControlName="fname">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Last Name</mat-label>
                        <input readonly matInput formControlName="lname">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Email Address</mat-label>
                        <input readonly matInput type="email" formControlName="email">
                    </mat-form-field>


                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Username</mat-label>
                        <input readonly matInput placeholder="8888888888" type="tel" formControlName="username">
                        <mat-hint>User's Primary Phone Number</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Phone No.</mat-label>
                        <input readonly matInput placeholder="8888888888" type="tel" formControlName="phone">
                    </mat-form-field>


                    <mat-form-field appearance="outline" class="col-md-6">
                        <mat-label>Date Of Birth</mat-label>
                        <input readonly matInput formControlName="dob" placeholder="MM/DD/YYYY">
                        <!-- <mat-datepicker-toggle matIconSuffix [for]="dob_picker"></mat-datepicker-toggle>
                        <mat-datepicker #dob_picker></mat-datepicker> -->
                    </mat-form-field>

                    <!-- <mat-form-field appearance="outline" class="col-md-12">
                        <mat-label>Address</mat-label>
                        <textarea readonly rows="2" matInput matInput formControlName="address"></textarea>
                    </mat-form-field> -->

                </div>

                <app-section-heading>Order Details</app-section-heading>

                <div class="row gx-4">

                    <div class="col-12">
                        <mat-progress-bar *ngIf="loadingState" mode="indeterminate"></mat-progress-bar>
                        <div class="mat-elevation-z2">
                            <div class="table-responsive">
                                <table class="w-100" mat-table [dataSource]="dataSource" matSort>


                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No. </th>
                                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="ordered_at">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ordered At </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.ordered_at | date: 'MMM d, y, h:mm:a'}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="order_status">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                        <td mat-cell *matCellDef="let element"> {{element.order_status}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="delivery_mode">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Mode </th>
                                        <td mat-cell *matCellDef="let element"> {{element.delivery_mode}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="delivery_type_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Type </th>
                                        <td mat-cell *matCellDef="let element"> {{element.delivery_type_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="assigned_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Assigned To </th>
                                        <td mat-cell *matCellDef="let element"> {{element.assigned_name === null ? '--'
                                            :
                                            element.assigned_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="actual_delivery_date">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Delivery Date
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.actual_delivery_date === null
                                            ?
                                            '--':element.actual_delivery_date | date: 'MMM d, y, h:mm a'}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dispatcher_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatcher</th>
                                        <td mat-cell *matCellDef="let element"> {{element.dispatcher_name === null ?
                                            '--' :
                                            element.dispatcher_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="delivery_partner_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Partner </th>
                                        <td mat-cell *matCellDef="let element"> {{element.delivery_partner_name === null
                                            ?
                                            '--' :
                                            element.delivery_partner_name}} </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                        (click)="openOrderDetailSidePanel(row.id)">
                                    </tr>

                                    <!-- Row shown when there is no matching data. -->
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="4">No records to display.</td>
                                    </tr>

                                </table>
                            </div>

                            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
                                aria-label="Select no. of rows per page">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 ">
                <div class="d-flex justify-content-between align-items-baseline">
                    <app-section-heading>Customer Chat</app-section-heading>
                    <button mat-raised-button color="warn" (click)="fetchCustomerChats()">Refresh
                        Chat</button>
                </div>

                <div class="row gx-4">
                    <div class="col-12">
                        <div class=" overflow-auto border shadow-sm" style="height: 50vh;" #scrollMe
                            [scrollTop]="scrollMe.scrollHeight">
                            <mat-card class="mb-3 p-0 w-75 "
                                [ngClass]="(item.created_by === item.customer_id)? 'me-auto':'ms-auto'"
                                *ngFor="let item of MessageList; "
                                [style.backgroundColor]="item.created_by === 98 ? '#90EE90' : null">
                                <mat-card-content>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <p class="mt-0 mb-0 pb-0">
                                            {{item.Created_by_String}} |
                                            {{(item.created_by === item.customer_id)? "Customer":"Internal"}} |
                                            {{item.created_at | date :'mediumDate'}}</p>

                                        <button mat-icon-button (click)="deleteMessage(item.message_id)">
                                            <mat-icon>delete</mat-icon></button>
                                    </div>
                                    <mat-divider class="mb-3"></mat-divider>
                                    <p> {{item.message}}</p>
                                    <!-- <mat-card-footer></mat-card-footer> -->
                                </mat-card-content>
                            </mat-card>
                        </div>


                        <hr />

                        <form [formGroup]="addMessageForm" id="addMessageForm" (ngSubmit)="AddLog()"
                            class="d-flex align-items-baseline">
                            <mat-form-field appearance="outline" class=" flex-grow-1">
                                <mat-label>Add Message</mat-label>
                                <textarea matInput formControlName="message"></textarea>
                            </mat-form-field>

                            <button mat-raised-button type="submit" color="primary"
                                [disabled]="addMessageForm.controls['message'].value === ''"
                                class="d-block ms-3">Send</button>
                        </form>


                    </div>
                </div>
            </div>
        </div>
    </form>


</app-template>