import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { OnInit, Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/Dialogs/Common/confirm-dialog/confirm-dialog.component';
import { AddUserDialogComponent } from 'src/app/Dialogs/Setup/Users/add-user-dialog/add-user-dialog.component';
import { environment } from 'src/environments/environment';


export interface UserListInterface {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  email_verified_at: string,
  password: string,
  role_id: number,
  remember_token: string,
  created_at: string,
  updated_at: string;
}

const ELEMENT_DATA: UserListInterface[] = [];

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {

  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.fetchUsers();
  }

  // This variable will be used, to enable disable the main Delete button (In table header)
  deleteButtonDisabled: boolean = true;

  loadingState: boolean = true;
  UserList: UserListInterface[] = [];

  @Input() value: string = '';
  displayedColumns: string[] = ['first_name', 'last_name', 'lk_role_string', 'email', 'username', 'phone', 'created_at', 'action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  selection = new SelectionModel<Object>(true, []);

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;


  fetchUsers(): Promise<void> {
    let obj = {
      session_id: sessionStorage.getItem('session_id'),
    };
    let promise = new Promise<void>((resolve, reject) => {
      this.http.post(`${this.baseUrl}/users_list `, obj)
        .subscribe({
          next: (data: any) => {
            console.log(data);
            if (data.length > 0) {
              this.UserList = data.filter((x: any) => x.role_id !== 1); // This filter will hide Super Admin
              this.dataSource = new MatTableDataSource(this.UserList);
            } else {
              this.UserList = [];
              this.dataSource = new MatTableDataSource(this.UserList);
            }
          }, error: (err: any) => {
            console.log(err);
            this.snackBar.open('Unable to fetch users.');
            this.loadingState = false;
            return reject();

          }, complete: () => {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.loadingState = false;
            return resolve();

          }
        });
    });
    return promise;
  }

  handleDelete(element: any) {
    console.log(element);
    this.dialog.open(ConfirmDialogComponent).afterClosed().subscribe(res => {
      if (res) {
        this.loadingState = true;
        this.snackBar.open(`Loading...`, undefined, { duration: undefined });

        this.http.delete(`${this.baseUrl}/delete_user/${element.id}`)
          .subscribe({
            next: async (res: any) => {
              console.log(res);
              await this.fetchUsers();
              this.loadingState = false;
              this.snackBar.open(`User deleted.`);
            }, error: (err: any) => {
              this.loadingState = false;
              this.snackBar.open(`An error occurred while deleting user, please try later`);
            }
          });
      }
    });
  }

  openAddUserDialog() {
    this.dialog.open(AddUserDialogComponent, {
      autoFocus: false,
    }).afterClosed().subscribe({
      next: async (result: boolean) => {
        if (result) {
          await this.fetchUsers();
          this.snackBar.open('User Added');
        }
      }
    });
  }

  applyFilter() {
    const filterValue = this.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  // Selection Functions %%%%%%%%%%%%%%%%%%%%%%%%%
  handleSelect(row: any) {
    this.selection.toggle(row);
    if (this.selection.hasValue()) this.deleteButtonDisabled = false;
    else this.deleteButtonDisabled = true;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.deleteButtonDisabled = true;
      return;
    }
    this.selection.select(...this.dataSource.data);
    this.deleteButtonDisabled = false;
  }
}
