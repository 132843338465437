import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import getFormData from 'src/app/Common/myFunctions';
import { FetchFunctionsService } from 'src/app/Service/fetch-functions.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-enquiry',
  templateUrl: './edit-enquiry.component.html',
  styleUrls: ['./edit-enquiry.component.scss']
})
export class EditEnquiryComponent {

  pipe = new DatePipe('en-US');
  enquiryId!: string;

  EnquiryType: any = [];
  EnquiryMode: any = [];
  EnquiryStatus: any = [];
  AccountUsers: any = [];

  baseUrl = environment.baseUrl;


  constructor(private http: HttpClient, private router: Router, private snackBar: MatSnackBar, private _activatedRoute: ActivatedRoute, private fetch: FetchFunctionsService) { }

  ngOnInit(): void {
    this.ViewEnquiry();

    this.fetchEnquiryTypes();
    this.fetchEnquiryModes();
    this.fetchEnquiryStatues();
    this.fetchUsers();

  }

  fetchEnquiryTypes() {
    this.fetch.FetchEnquiryTypes().subscribe((res: any) => {
      if (res.success) {
        this.EnquiryType = JSON.parse(res.data);
      } else {
        console.log(res);
        this.snackBar.open('Unable to fetch enquiry types.');
      }
    });
  }

  fetchEnquiryModes() {
    this.fetch.FetchEnquiryModes().subscribe((res: any) => {
      if (res.success) {
        this.EnquiryMode = JSON.parse(res.data);
      } else {
        console.log(res);
        this.snackBar.open('Unable to fetch enquiry modes.');
      }
    });
  }

  fetchEnquiryStatues() {
    this.fetch.FetchEnquiryStatues().subscribe((res: any) => {
      if (res.success) {
        this.EnquiryStatus = JSON.parse(res.data);
      } else {
        console.log(res);
        this.snackBar.open('Unable to fetch enquiry statues.');
      }
    });
  }

  fetchUsers() {
    this.fetch.FetchUsers().subscribe((res: any) => {
      if (res.success) {
        this.AccountUsers = JSON.parse(res.data);
      } else {
        console.log(res);
        this.snackBar.open('Unable to fetch account users.');
      }
    });
  }

  ViewEnquiry() {
    this._activatedRoute.paramMap.subscribe(params => {
      this.enquiryId = params.get('id')!;
      let obj = { session_id: sessionStorage.getItem('session_id'), enquiry_id: this.enquiryId };

      this.http.post(`${this.baseUrl}/view_enquiry.php`, obj)
        .subscribe((res: any) => {

          if (res.success) {
            let enqData = JSON.parse(res.data);
            this.editEnquiryForm.setValue({
              fname: enqData.fname,
              lname: enqData.lname,
              email: enqData.email,
              phone: enqData.phone,
              assigned_to: enqData.assigned_to,
              account_id: enqData.account_id,
              enquiry_date: enqData.enquiry_date,
              enquiry_status_id: enqData.enquiry_status_id,
              enquiry_type_id: enqData.enquiry_type_id,
              enquiry_mode_id: enqData.enquiry_mode_id,
              enquiry_details: enqData.enquiry_details
            });
          } else {
            console.log(res);
          }
        });

    });
  }

  editEnquiryForm = new FormGroup({
    fname: new FormControl('', [Validators.required]),
    lname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required]),
    assigned_to: new FormControl('', [Validators.required]),
    account_id: new FormControl(sessionStorage.getItem('account_id'),),
    enquiry_date: new FormControl('', [Validators.required]),
    enquiry_status_id: new FormControl('', [Validators.required]),
    enquiry_type_id: new FormControl("", [Validators.required]),
    enquiry_mode_id: new FormControl("", [Validators.required]),
    enquiry_details: new FormControl('', [Validators.required]),
  });

  DeleteEnquiry() {
    this.snackBar.open('Loading...', undefined, { duration: undefined });

    let enqIdArray: number[] = [];
    enqIdArray.push(Number(this.enquiryId));

    const obj = { session_id: sessionStorage.getItem('session_id'), enquiry_id: enqIdArray };

    this.http.post(`${this.baseUrl}/delete_enquiries`, obj)
      .subscribe((res: any) => {
        if (res.success) {
          this.snackBar.open('Enquiry deleted');
          this.router.navigate(['/enquiries']);

        } else {
          this.snackBar.open('Error occurred, please try again later');
          console.log(res);
        }

      });
  }

  onSubmit(formDirective: FormGroupDirective) {
    if (this.editEnquiryForm.valid) {
      this.snackBar.open('Loading...', undefined, { duration: undefined });

      let formData: FormData = getFormData(this.editEnquiryForm.value);
      console.log(this.editEnquiryForm.value);

      formData.append('session_id', sessionStorage.getItem('session_id')!);
      formData.append('enquiry_id', this.enquiryId);
      formData.set('enquiry_date', this.pipe.transform(this.editEnquiryForm.value['enquiry_date'], 'yyyy-MM-dd')!);


      this.http.post(`${this.baseUrl}/edit_enquiry`, formData)
        .subscribe((res: any) => {
          console.log(res);
          if (res.success) {

            this.snackBar.open('Enquiry Updated');
          } else {
            console.log(res);
            this.snackBar.open('Error occurred while adding enquiry.');
          }
        });

    }

  }
}
