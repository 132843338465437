import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { DialogService } from 'src/app/Service/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface EnquiryData {
  account_id: string,
  enquiry_id: string,
  user_id: string,
  fname: string,
  lname: string,
  phone: string,
  email: string,
  enquiry_date: string,
  enquiry_mode_id: string,
  enquiry_type_id: string,
  enquiry_details: string,
  enquiry_status_id: string,
  assigned_to: string,
  created_by: string,
  created_at: string,
  updated_by: string | null,
  updated_at: string | null,
  is_deleted: string;
}

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent {


  constructor(private http: HttpClient, private dialogService: DialogService, private snackBar: MatSnackBar) { }

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  loadingState: boolean = false;

  ngOnInit(): void {
    this.FetchEnquiries();
  }

  FetchEnquiries() {
    // this.loadingState = true;
    // this.http.post('https://portalwiz.net/php/enquiries/enquiries.php', { session_id: sessionStorage.getItem('session_id') , account_id: sessionStorage.getItem('account_id')})
    //   .subscribe((data: any) => {
    //     console.log(data)
    //     if (data.success && data.data) {
    //       this.EnquiryList = JSON.parse(data.data);
    //     console.log(this.EnquiryList)

    //       this.dataSource = new MatTableDataSource(this.EnquiryList);
    //       this.dataSource.paginator = this.paginator;
    //     } else {
    //       this.EnquiryList = [];
    //       this.dataSource = new MatTableDataSource(this.EnquiryList);
    //       this.dataSource.paginator = this.paginator;
    //     }
    //     this.loadingState = false;

    //   });
  }

  EnquiryList: EnquiryData[] = [];

  // This variable will be used, to enable disable the main Delete button (In table header)
  deleteButtonDisabled: boolean = true;

  @Input() value: string = '';
  displayedColumns: string[] = ['select', 'index', 'fname', 'lname', 'phone', 'email', 'enquiry_date', 'enquiry_status_id', 'assigned_to', 'actions'];
  dataSource = new MatTableDataSource(this.EnquiryList);
  selection = new SelectionModel<Object>(true, []);

  open() {
    this.dialogService.openConfirmDialog();
  }

  DeleteSelected(row?: any) {
    this.loadingState = true;

    let enqIdArray: number[] = [];
    if (row) {
      enqIdArray.push(Number(row.enquiry_id));
    } else enqIdArray = this.selection.selected.map((enq: any) => Number(enq.enquiry_id));

    const obj = { session_id: sessionStorage.getItem('session_id'), enquiry_id: enqIdArray };

    this.http.post('https://portalwiz.net/php/enquiries/delete_enquiries.php', obj)
      .subscribe((res: any) => {
        this.selection.clear();
        this.FetchEnquiries();
        this.loadingState = false;
        this.snackBar.open(`${enqIdArray.length} record(s) deleted.`);
      });
  }


  /* Filter field logic */
  applyFilter(event: Event) {
    const filterValue = this.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  handleSelect(row: any) {
    this.selection.toggle(row);
    if (this.selection.hasValue()) this.deleteButtonDisabled = false;
    else this.deleteButtonDisabled = true;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.deleteButtonDisabled = true;
      return;
    }
    this.selection.select(...this.dataSource.data);
    this.deleteButtonDisabled = false;

  }
}
