import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { FetchFunctionsService } from 'src/app/Service/fetch-functions.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.scss']
})
export class EditOrderComponent {

  baseUrl = environment.baseUrl;
  storageUrl = environment.storageUrl;
  loadingState: boolean = true;
  customerId!: string;

  loading: boolean = false;

  dispatcherFieldDisabled: boolean = true;
  DeliveryPartnerFieldDisabled: boolean = true;


  pipe = new DatePipe('en-US');
  orderId: string = this._activatedRoute.snapshot.paramMap.get('id')!;

  prescriptionAttachmentList = [];
  invoiceAttachmentList: any = {};

  assignedToList = [];
  deliveryPartnerList = [];
  dispatcherList = [];
  estimatedDeliverySlotList = [];
  orderStatusList = [];
  paymentStatusList = [];
  deliveryModeList = [];
  deliveryTypeList = [];

  selectedFile: File[] | undefined;

  MessageList: any = [];

  @ViewChild('fileInput')
  fileInputVariable: ElementRef | undefined;

  constructor(private http: HttpClient, private router: Router, private snackBar: MatSnackBar, private _activatedRoute: ActivatedRoute, private fetch: FetchFunctionsService, public dialog: MatDialog, private _lightbox: Lightbox, private cdr: ChangeDetectorRef) { }

  addMessageForm = new FormGroup({
    message: new FormControl(''),
    customer_id: new FormControl(""),
    created_by: new FormControl(sessionStorage.getItem('user_id')),
    user_id: new FormControl(sessionStorage.getItem('user_id')),
  });

  editOrderForm = new FormGroup({
    actual_delivery_date: new FormControl(''),
    additional_info: new FormControl(''),
    address: new FormControl(''),
    user_address_id: new FormControl(null),
    assigned_to: new FormControl(''),
    created_at: new FormControl(''),
    created_by: new FormControl(''),
    delivery_mode_id: new FormControl(null),
    delivery_partner: new FormControl({ value: '', disabled: this.DeliveryPartnerFieldDisabled }),
    delivery_type_id: new FormControl(null),
    dispatcher: new FormControl({ value: '', disabled: this.dispatcherFieldDisabled }),
    esti_delivery_date: new FormControl(''),
    esti_delivery_slot: new FormControl(''),
    id: new FormControl(''),
    order_status_id: new FormControl(null),
    ordered_at: new FormControl(''),
    invoice_no: new FormControl(''),
    name: new FormControl(''),
    email: new FormControl(''),
    payment_status_id: new FormControl('')
  });

  ngOnInit(): void {
    this.ViewOrder();
    this.FetchDropDownValues();
  }

  ViewOrder(): Promise<void> {
    this.http.get(`${this.baseUrl}/order_detail/${this.orderId}`)
      .subscribe((res: any) => {
        console.log(res);
        this.prescriptionAttachmentList = res[0].file_path;
        this.invoiceAttachmentList = res[0].invoice_path;
        this.editOrderForm.setValue({
          id: this.orderId,
          additional_info: res[0].additional_info === null ? '--' : res[0].additional_info,
          ordered_at: res[0].ordered_at,
          actual_delivery_date: res[0].actual_delivery_date, address: res[0].address, user_address_id: res[0].user_address_id,
          assigned_to: res[0].assigned_to, created_at: res[0].created_at, created_by: res[0].created_by,
          delivery_mode_id: res[0].delivery_mode_id, delivery_partner: res[0].delivery_partner, delivery_type_id: res[0].delivery_type_id,
          dispatcher: res[0].dispatcher, payment_status_id: res[0].payment_status_id,
          esti_delivery_date: res[0].esti_delivery_date, esti_delivery_slot: res[0].esti_delivery_slot,
          order_status_id: res[0].order_status_id, invoice_no: res[0].invoice_no,
          name: `${res[0].first_name} ${res[0].last_name}`, email: res[0].email,
        });
        this.customerId = res[0].id;
        this.fetchCustomerChats(res[0].id);

        if (res[0].delivery_mode_id === 1 || res[0].order_status_id < 5) {
          this.editOrderForm.controls.delivery_partner.disable();
          this.editOrderForm.controls.dispatcher.disable();
        } else {
          this.editOrderForm.controls.delivery_partner.enable();
          this.editOrderForm.controls.dispatcher.enable();
        }
        this.loadingState = false;
      });
    return Promise.resolve();
  }

  fetchCustomerChats(id?: number): Promise<any> {
    this.snackBar.open('Loading...', undefined, { duration: undefined });
    let obj = { session_id: sessionStorage.getItem('session_id'), customer_id: id ? id : this.customerId };
    console.log(obj);
    this.http.post(`${this.baseUrl}/fetch_message`, obj)
      .subscribe({
        next: (res: any) => {
          console.log(res);
          this.MessageList = res;
        }, error: (err: any) => {
          console.log(err);
          this.snackBar.open('Error occurred while fetching data, please try later');
        }, complete: () => {
          this.snackBar.dismiss();
          this.cdr.detectChanges(); // Manually trigger change detection
        }
      });
    // });
    return Promise.resolve();
  }


  AddLog() {
    if (this.addMessageForm.valid) {
      this.loading = true;
      this.snackBar.open('Loading...', undefined, { duration: undefined });

      this.addMessageForm.patchValue({ customer_id: this.customerId });
      console.log(this.addMessageForm.value);
      this.http.post(`${this.baseUrl}/add_message`, this.addMessageForm.value)
        .subscribe({
          next: (res: any) => {
            console.log(res);
            if (res.success) {
              this.fetchCustomerChats().then(() => {
                // this.addMessageForm.markAsPristine();
                // this.addMessageForm.markAsUntouched();
                // this.addMessageForm.reset();
                this.addMessageForm.patchValue({ message: '' });
                this.snackBar.open('Message added successfully');
              });
            } else {
              console.log(res);
              this.snackBar.open('Error occurred while adding message.');
            }
          }, error: (err: any) => {
            console.log(err);
            this.snackBar.open('Error occurred while adding message.');
          }, complete: () => {
            this.loading = false;
          }
        });
    }
  }

  deleteMessage(id: number) {
    this.snackBar.open('Loading...', undefined, { duration: undefined });

    this.http.post(`${this.baseUrl}/delete_message`, { message_id: id })
      .subscribe({
        next: (res: any) => {
          console.log(res);
          if (res.success) {
            this.fetchCustomerChats().then(() => {
              this.snackBar.open('Message deleted');
            });
          } else {
            console.log(res);
            this.snackBar.open('Error occurred while deleting message.');
          }
        }, error: (err: any) => {
          console.log(err);
          this.snackBar.open('Error occurred while deleting message.');
        }, complete: () => {
          this.loading = false;
        }
      });
  }

  FetchDropDownValues() {
    this.http.get(`${this.baseUrl}/dropdown`)
      .subscribe((res: any) => {
        console.log(res);
        this.assignedToList = res.assigned_to;
        this.deliveryPartnerList = res.delivery_partner;
        this.dispatcherList = res.dispatcher;
        this.estimatedDeliverySlotList = res.estimated_delivery_slot;
        this.orderStatusList = res.order_status;
        this.paymentStatusList = res.payment_status;
        this.deliveryModeList = res.delivery_modes;
        this.deliveryTypeList = res.delivery_types;
      });
  }

  onSubmit() {
    if (this.editOrderForm.valid) {
      this.loadingState = true;
      this.snackBar.open('Loading...', undefined, { duration: undefined });
      let data = this.editOrderForm.controls;
      // console.log(typeof data.delivery_mode_id.value);

      let obj = {
        user_id: sessionStorage.getItem('user_id'), delivery_type_id: data.delivery_type_id.value, additional_info: data.additional_info.value,
        address: data.user_address_id.value, delivery_mode_id: data.delivery_mode_id.value, order_status_id: data.order_status_id.value,
        assigned_to: data.assigned_to.value,
        dispatcher: (data.order_status_id.value! < 5 || data.delivery_mode_id.value === 1) ? null : data.dispatcher.value,
        delivery_partner: (data.order_status_id.value! < 5 || data.delivery_mode_id.value === 1) ? null : data.delivery_partner.value,
        esti_delivery_slot: data.esti_delivery_slot.value,
        esti_delivery_date: data.esti_delivery_date.value !== null ? new Date(data.esti_delivery_date.value!).toLocaleDateString() : null,
        actual_delivery_date: data.actual_delivery_date.value !== null ? new Date(data.actual_delivery_date.value!).toLocaleDateString() : null,
        ordered_at: new Date(data.ordered_at.value!).toISOString(),
        created_by: data.created_by.value, payment_status_id: data.payment_status_id.value, invoice_no: data.invoice_no.value
      };
      console.log(obj);
      this.http.put(`${this.baseUrl}/update_order/${this.orderId}`, obj)
        .subscribe({
          next: async (res: any) => {
            // console.log(res);
            if (res.success) {
              // console.log(res);
              await this.ViewOrder();
              this.snackBar.open('Order Updated');
            } else {
              // console.log(res);
              this.snackBar.open('Error occurred while updating the order.');
            }
          }, error: (err: any) => {
            console.log(err);
            this.snackBar.open('Error occurred while updating the order.');
          }
        });
      this.loadingState = false;

    }
  }

  getCaption(index: number, attachmentType: number): string {
    const details = this.editOrderForm.controls;
    const formattedDate = this.pipe.transform(details['ordered_at'].value, 'MMM d, y, h:mm a');

    return `Image: ${attachmentType === 1 ?
      `ID: ${details['id'].value}.${index + 1} - ${formattedDate}`
      : 'Invoice Attachment'}`;
  }

  open(index: any, attachmentType: number) {

    // attachmentType = 1 => means we need to take prescriptionAttachmentList
    // attachmentType = 0 => means we need to take invoiceAttachmentList

    let list = attachmentType === 1 ? this.prescriptionAttachmentList : this.invoiceAttachmentList;

    let obj = [{
      src: `${this.storageUrl}/${list[index]}`,
      downloadUrl: `${this.storageUrl}/${list[index]}`,
      caption: this.getCaption(index, attachmentType),
      thumb: `${this.storageUrl}/${list[index]}`
    }];
    this._lightbox.open(obj, 0, { disableScrolling: true, centerVertically: true });
  }


  handleFileUpload(e: any) {
    this.selectedFile = e.target!.files;
    // console.log(this.selectedFile);

  }

  uploadFile(e: Event) {
    this.loadingState = true;
    this.snackBar.open('Uploading...');

    const fd = new FormData();
    for (let i = 0; i < this.selectedFile!.length; i++) {
      fd.append(`file[${i}]`, this.selectedFile![i]);
    }
    fd.append('user_id', sessionStorage.getItem('user_id')!);
    fd.append('order_id', this.orderId);


    this.http.post(`${this.baseUrl}/add_invoice`, fd)
      .subscribe({
        next: async (res: any) => {
          console.log(res);
          if (res.success) {
            this.snackBar.open('File uploaded successfully');
            this.fileInputVariable!.nativeElement.value = "";
            // loadingState is setting false in above ViewOrder Method
            await this.ViewOrder();
          } else {
            this.snackBar.open('File uploaded failed, please try later');
            this.loadingState = false;
          }
        }, error: (err: any) => {
          console.log(err);
          this.snackBar.open('File uploaded failed, please try later');
          this.loadingState = false;
        }
      });
  }

  deleteInvoiceAttachment(e: Event, i: any) {
    e.stopPropagation();
    this.loadingState = true;
    this.snackBar.open('Deleting...');
    this.http.delete(`${this.baseUrl}/delete_invoice/${i}`)
      .subscribe(async (res: any) => {
        // console.log(res);
        if (res.success) {
          this.snackBar.open('Attachment deleted');
        } else {
          this.snackBar.open('An error occurred, please try later');
        }
        await this.ViewOrder();
        // loadingState is setting false in above ViewOrder Method
      });
  }
}
