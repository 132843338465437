import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/Dialogs/Common/confirm-dialog/confirm-dialog.component';
import { ResetPasswordDialogComponent } from 'src/app/Dialogs/Profile/reset-password-dialog/reset-password-dialog.component';
import { FetchFunctionsService } from 'src/app/Service/fetch-functions.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent {

  pipe = new DatePipe('en-US');
  userId!: string;
  DocumentList = [];
  EnquiryList = [];
  loading: boolean = false;
  roleList = [];


  // URls
  baseUrl = environment.baseUrl;
  domainUrl = environment.domainUrl;
  storageUrl = environment.storageUrl;
  loadingState: boolean = false;


  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router, private snackBar: MatSnackBar, private _activatedRoute: ActivatedRoute, private fetch: FetchFunctionsService) { }

  editUserForm = new FormGroup({
    user_id: new FormControl(''),
    role_id: new FormControl('', [Validators.required]),
    fname: new FormControl('', [Validators.required]),
    lname: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl(''),
    username: new FormControl('', [Validators.required]),
    dob: new FormControl(''),
    updated_by: new FormControl(''),
  });

  ngOnInit(): void {
    this.fetchUserDetails();
    this.fetchRoles();
  }

  fetchRoles() {
    this.fetch.FetchRoles().subscribe((res: any) => {
      // console.log(res);
      this.roleList = res.filter((x: any) => x.role_id !== 1);
    });
  }

  fetchUserDetails() {
    this.snackBar.open('Loading...', undefined, { duration: undefined });
    this._activatedRoute.paramMap.subscribe(params => {
      this.userId = params.get('id')!;
      let obj = { session_id: sessionStorage.getItem('session_id'), user_id: this.userId };

      this.http.post(`${this.baseUrl}/fetch_user`, obj)
        .subscribe({
          next: (res: any) => {
            console.log(res);
            this.editUserForm.patchValue({
              user_id: res[0].id,
              role_id: res[0].role_id,
              fname: res[0].first_name,
              lname: res[0].last_name,
              phone: res[0].phone,
              email: res[0].email,
              username: res[0].username,
              dob: res[0].dob === "0000-00-00" ? null : res[0].dob,
            });


          }, error: (err: any) => {
            console.log(err);
            this.snackBar.open('Error occurred while fetching data, please try later');
          }, complete: () => {
            this.snackBar.dismiss();
          }
        });
    });
  }

  onSubmit() {
    if (this.editUserForm.valid) {
      this.loading = true;

      let obj = this.editUserForm.value;
      obj.dob = this.pipe.transform(this.editUserForm.controls['dob'].value, 'yyyy-MM-dd');
      obj.updated_by = sessionStorage.getItem('user_id');
      console.log(obj);
      this.http.post(`${this.baseUrl}/edit_user`, obj)
        .subscribe({
          next: (res: any) => {
            console.log(res);
            if (res.success) {
              this.snackBar.open('User updated successfully');

            } else {
              console.log(res);
              this.snackBar.open('Error occurred while updating user.');

            }
          }, error: (err: any) => {
            console.log(err);
            this.snackBar.open('Error occurred while updating user.');

          }, complete: () => {
            this.loading = false;
          }
        });
    }
  }

  handleResetPassword() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Reset Password Confirmation",
        content: "Are you sure you want to reset the password?",
        proceedButtonText: 'Reset'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.dialog.open(ResetPasswordDialogComponent,
          { data: { id: this.userId } }
        );
      }
    });
  }

  handleDelete() {
    this.dialog.open(ConfirmDialogComponent).afterClosed().subscribe(res => {
      if (res) {
        this.loadingState = true;
        this.snackBar.open(`Loading...`, undefined, { duration: undefined });

        this.http.delete(`${this.baseUrl}/delete_user/${this.userId}`)
          .subscribe({
            next: async (res: any) => {
              console.log(res);
              this.router.navigate(["/users"]);
              this.loadingState = false;
              this.snackBar.open(`User deleted.`);
            }, error: (err: any) => {
              this.loadingState = false;
              this.snackBar.open(`An error occurred while deleting user, please try later`);
            }
          });
      }
    });
  }
}
