<h2 mat-dialog-title>Reset Password</h2>
<div mat-dialog-content>
    <p>To reset your password, enter the fields below</p>

    <form id="resetPasswordForm" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" class="d-flex flex-column">
        <mat-form-field appearance="fill" class="mb-3">
            <mat-label>Old Password</mat-label>
            <input type="text" matInput formControlName="old_password">
            <mat-error *ngIf="resetPasswordForm.controls['old_password'].hasError('required') ">
                Old password is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="mb-3">
            <mat-label>New Password</mat-label>
            <input type="text" matInput formControlName="new_password">
            <mat-error *ngIf="resetPasswordForm.controls['new_password'].hasError('required') ">
                Password is required
            </mat-error>
            <mat-error *ngIf="resetPasswordForm.controls['new_password'].hasError('minlength')">
                Password must be minimum 8 characters long
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="">
            <mat-label>Confirm Password</mat-label>
            <input type="text" matInput formControlName="confirmPassword">
            <mat-error *ngIf="resetPasswordForm.controls['confirmPassword'].hasError('required')">
                Confirm password field required
            </mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
    <button [disabled]="!resetPasswordForm.valid || loading" mat-raised-button type="submit" form="resetPasswordForm">Reset</button>
</div>