<div class="container-fluid d-flex justify-content-center align-items-center bg-light" style="min-height: 100vh;">
    <div class="container card shadow-sm bg-white my-5">
        <div class="row px-3 mx-auto">
            <!-- Image- Left Side -->
            <div class="col-md-6 me-auto p-5 d-none d-md-flex justify-content-center align-items-center">
                <img class="img-fluid" src="assets/svg/forgot_password.svg" />
            </div>

            <!-- Logo & Form - Right Side -->
            <div class="col-md-5 bg-white d-flex justify-content-center align-items-center p-5">

                <!-- Enter Email Div -->
                <div *ngIf="enterUsernameDiv">
                    <p class="display-6 ">Forgot Password?</p>
                    <p class="lead mb-4">Don't worry, we will help you. Please enter your username below.</p>
                    <form [formGroup]="usernameForm" (ngSubmit)="usernameSubmit()">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Username</mat-label>
                            <input type="text" matInput formControlName="username">
                            <mat-error *ngIf="usernameForm.controls['username'].hasError('required') ">
                                Username is required
                            </mat-error>
                            <!-- <mat-error *ngIf="usernameForm.controls['email'].hasError('email') ">
                                Enter a valid email
                            </mat-error> -->
                        </mat-form-field>
                        <button type="submit" mat-raised-button>Submit</button>
                    </form>

                </div>

                <!-- Enter OTP Div -->
                <div *ngIf="enterOtpDiv">
                    <p class="display-6 ">OTP Verification</p>
                    <p class="lead mb-4">An OTP has been sent to your registered email address, please enter it below.
                    </p>
                    <form [formGroup]="otpForm" (ngSubmit)="otpSubmit()">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Enter OTP</mat-label>
                            <input type="text" matInput formControlName="otp">
                            <mat-error *ngIf="otpForm.controls['otp'].hasError('required') ">
                                OTP is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>New Password</mat-label>
                            <input type="text" matInput formControlName="new_password">
                            <mat-error *ngIf="otpForm.controls['new_password'].hasError('required') ">
                                Password is required
                            </mat-error>
                            <mat-error *ngIf="otpForm.controls['new_password'].hasError('minlength')">
                                Password must be minimum 8 characters long
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Confirm Password</mat-label>
                            <input type="text" matInput formControlName="confirmPassword">
                            <mat-error *ngIf="otpForm.controls['confirmPassword'].hasError('required')">
                                Confirm password field required
                            </mat-error>
                            <mat-error (*ngIf)="passwordMatchError()">
                                Password didn't match
                            </mat-error>
                        </mat-form-field>
                        <div class="d-flex justify-content-between">
                            <button type="submit" mat-raised-button>Submit</button>
                            <button (click)="ResendOTP()" type="button" mat-button>Resend OTP</button>
                        </div>
                    </form>
                </div>
              
            </div>
        </div>
    </div>
</div>