<app-template>
    <app-breadcrumb>
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item">Setup</li>
        <li class="breadcrumb-item active">Customers</li>
    </app-breadcrumb>


    <app-main-heading>Customers</app-main-heading>

    <div class="row d-flex">
        <div class="col-5 me-auto">
            <mat-form-field class="me-3 w-100">
                <mat-label>Search</mat-label>
                <input matInput type="text" [(ngModel)]="value" (keyup)="applyFilter()">
                <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''; applyFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="col-auto">
            <!-- <button mat-raised-button>Add</button> -->
            <!-- <button (click)="openAddUserDialog()" mat-raised-button>Add</button> -->
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-progress-bar *ngIf="loadingState" mode="indeterminate"></mat-progress-bar>
            <div class="mat-elevation-z2">
                <div class="table-responsive">
                    <table class="w-100" mat-table [dataSource]="dataSource" matSort>

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell (click)="$event.stopPropagation()" *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? handleSelect(row) : null" [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="index">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
                            <td mat-cell *matCellDef="let element, let i = index"> {{i+1}} </td>
                        </ng-container>

                        <ng-container matColumnDef="first_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="last_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
                        </ng-container>



                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
                            <td mat-cell *matCellDef="let element"> {{element.role_id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                        </ng-container>

                        <ng-container matColumnDef="username">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
                            <td mat-cell *matCellDef="let element"> {{element.username}} </td>
                        </ng-container>


                        <ng-container matColumnDef="phone">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number</th>
                            <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                        </ng-container>


                        <ng-container matColumnDef="created_at">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                            <td mat-cell *matCellDef="let element"> {{element.created_at | date}} </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action(s) </th>
                            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                                <button mat-icon-button (click)="handleDelete(element)" matTooltip="Delete User"
                                    matTooltipClass="default-tooltip" matTooltipPosition="right">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                        <tr mat-row *matRowDef=" let row; columns: displayedColumns;"
                            [routerLink]="['/customer-details/', row.id]"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="12">No records to display.</td>
                        </tr>

                    </table>
                </div>
                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
                    aria-label="Select no. of rows per page">
                </mat-paginator>
            </div>
        </div>
    </div>
</app-template>