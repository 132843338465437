import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FetchFunctionsService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  fetchObj = { session_id: sessionStorage.getItem('session_id') };

  FetchEnquiryTypes() {
    return this.http.post(`${this.baseUrl}/enquiry_types`, this.fetchObj);
  }

  FetchEnquiryModes() {
    return this.http.post(`${this.baseUrl}/enquiry_modes`, this.fetchObj);
  }

  FetchEnquiryStatues() {
    return this.http.post(`${this.baseUrl}/enquiry_status`, this.fetchObj);
  }

  FetchUsers() {
    return this.http.post(`${this.baseUrl}/account_users`, this.fetchObj);
  }

  FetchRoles() {
    return this.http.post(`${this.baseUrl}/fetch_roles`, this.fetchObj);
  }

  FetchProfilePicturePath() {
    return this.http.post(`${this.baseUrl}/account_users`, { ...this.fetchObj, flag: true });
  }
}
