import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number; },
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ResetPasswordDialogComponent>) { }


  baseUrl = environment.baseUrl;
  loading: boolean = false;

  resetPasswordForm = new FormGroup({
    old_password: new FormControl('', [Validators.required]),
    new_password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    user_id: new FormControl(this.data.id),
    confirmPassword: new FormControl('', [Validators.required]),
  });

  checkConfirmPassword() {

    return (this.resetPasswordForm.controls.new_password.value === this.resetPasswordForm.controls.confirmPassword.value ? true : false);
  }

  onSubmit() {
    if (this.resetPasswordForm.valid && this.checkConfirmPassword()) {
      this.loading = true;
      console.log(this.resetPasswordForm.value);
      this.http.post(`${this.baseUrl}/reset_password`, this.resetPasswordForm.value)
        .subscribe({
          next: (res: any) => {
            console.log(res);
            if (res.success) {
              this.snackBar.open('Password updated successfully');
              this.resetPasswordForm.reset();
              this.dialogRef.close();
            } else {
              console.log(res);
              this.snackBar.open('Unable to reset the password, please try later');

            }
          }, error: (err: any) => {
            console.log(err);
            this.snackBar.open('Unable to reset the password, please try later');
          },
          complete: () => {
            this.loading = false;
          }
        });
    } else {
      this.snackBar.open("Confirm Password didn't match");
    }
  }
}
