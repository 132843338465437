import { Component } from '@angular/core';
import { SidebarStateService } from 'src/app/Service/sidebar-state.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent {
  constructor(public sidebarStateService: SidebarStateService) { }

}
