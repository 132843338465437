import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent {

  pipe = new DatePipe('en-US');

  EnquiryType: any = [];
  EnquiryMode: any = [];
  EnquiryStatus: any = [];
  AccountUsers: any = [];


  constructor(private snackBar: MatSnackBar) { }


  addContactForm = new FormGroup({
    fname: new FormControl(''),
    lname: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    assigned_to: new FormControl(''),
    account_id: new FormControl(sessionStorage.getItem('account_id'),),
    enquiry_date: new FormControl(new Date().toISOString()),
    enquiry_status_id: new FormControl(''),
    enquiry_type_id: new FormControl(""),
    enquiry_mode_id: new FormControl(""),
    enquiry_details: new FormControl(''),
  });


  onSubmit(formDirective: FormGroupDirective) {
    if (this.addContactForm.valid) {
      this.snackBar.open('Module under progress...');


      // this.snackBar.open('Loading...', undefined, { duration: undefined });

      // let formData: FormData = getFormData(this.addContactForm.value);
      // console.log(this.addContactForm.value)
      // formData.append('session_id', sessionStorage.getItem('session_id')!);
      // formData.set('enquiry_date', this.pipe.transform(this.addContactForm.value['enquiry_date'], 'yyyy-MM-dd')!);

      // this.http.post('https://portalwiz.net/php/enquiries/add_enquiries.php', formData)
      //   .subscribe((res: any) => {
      //     if (res.success) {
      //       formDirective.resetForm();
      //       this.addContactForm.reset();
      //       this.snackBar.open('Enquiry Added');
      //     } else {
      //       console.log(res);
      //       this.snackBar.open('Error occurred while adding enquiry.');
      //     }
      //   });

    }

  }
}
